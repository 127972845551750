import React, { useContext } from 'react';

export const PhoneInHand = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='101'
            height='89'
            viewBox='0 0 101 89'
            fill='none'
        >
            <path
                d='M77.5414 85.8959H20.4282C17.0458 85.8959 14.3039 83.154 14.3039 79.7716V79.7716C14.3039 79.53 14.108 79.3342 13.8664 79.3342H3.2207C2.94456 79.3342 2.7207 79.1103 2.7207 78.8342L2.7207 50.7752C2.7207 50.4991 2.94456 50.2752 3.2207 50.2752H13.7488C14.0455 50.2752 14.2765 50.0157 14.2484 49.7203C13.544 42.3109 14.7533 39.1254 19.4315 35.4293C19.5526 35.3337 19.6258 35.1867 19.6258 35.0324L19.6258 12.5684C19.6258 11.3575 20.697 10.4157 21.8876 10.637C25.9263 11.3877 28.0594 12.526 30.8959 14.967'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M79.3576 49.4954V9C79.3576 5.68629 76.6713 3 73.3576 3L38.7734 3C35.4597 3 32.7734 5.68629 32.7734 9L32.7734 80.9942C32.7734 83.7588 35.0146 86 37.7792 86V86'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M32.9629 14.1447L79.1625 14.1447'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M32.9609 73.1285H79.1606'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M46.334 43.2036C51.1767 51.8353 60.3436 52.1805 65.5316 43.2036'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <ellipse
                cx='65.1677'
                cy='31.9959'
                rx='3.12469'
                ry='3.11859'
                fill={color ? color : '#000000'}
            />
            <ellipse
                cx='47.072'
                cy='31.9946'
                rx='3.12469'
                ry='3.11859'
                fill={color ? color : '#000000'}
            />
            <path
                d='M79.9145 37.658H93.5781C93.6538 37.658 93.7297 37.6755 93.7974 37.7094C98.9136 40.2725 99.1345 46.4042 93.8156 49.8186C93.7368 49.8691 93.6434 49.8961 93.5498 49.8961H84.2345M84.2345 49.8961H72.7014C72.6073 49.8961 72.5142 49.9226 72.4357 49.9745C68.9226 52.2965 68.5794 57.6399 72.4155 61.1895C72.5054 61.2728 72.6251 61.3183 72.7477 61.3183H84.1218C84.196 61.3183 84.2708 61.3013 84.3375 61.2686C89.9479 58.5217 89.6988 51.8262 84.2345 49.8961Z'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M72.2027 81.0462C72.0867 80.2045 72.0795 79.3875 72.1906 78.5636C72.5746 75.7164 75.5571 74.2292 78.4301 74.2292H79.3696C82.3993 74.2292 85.4948 75.8951 85.8375 78.9054C85.9214 79.6427 85.9198 80.3685 85.8354 81.1045C85.4831 84.1804 82.3377 85.9182 79.2417 85.9182H78.923C75.7919 85.9182 72.63 84.148 72.2027 81.0462Z'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M70.7709 68.428C70.6113 67.4608 70.6026 66.529 70.7602 65.585C71.2199 62.8305 74.1344 61.4382 76.927 61.4382H78.8603C81.8145 61.4382 84.85 63.0111 85.2623 65.9363C85.3831 66.793 85.3809 67.6319 85.2607 68.4867C84.8399 71.4801 81.7523 73.1272 78.7294 73.1272H77.4301C74.3708 73.1272 71.2689 71.4464 70.7709 68.428Z'
                stroke={color ? color : '#000000'}
                strokeWidth='5'
                strokeLinecap='round'
            />
        </svg>
    );
};
