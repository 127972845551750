import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { FloatButton } from 'antd';

import { Heading } from 'shared/layouts/LandingLayout/screen/Heading';
import { Intro } from 'shared/layouts/LandingLayout/screen/Intro';

import { RefsProps } from '../types/landing.interface';
import { TelegramAdvantages } from '../screen/TelegramAdvantages';
import { TelegramMiniApps } from '../screen/TelegramMiniApps';
import { PathToPurchase } from '../screen/PathToPurchase';
import { OurClients } from '../screen/OurClients';
import { IndividualConditions } from '../screen/IndividualConditions';
import { AboutUs } from '../screen/AboutUs';
import { EcomOpportunities } from '../screen/EcomOpportunities';
import { SlickStyle } from '../../../ui/App/slick';
import { Feedback } from '../../AppLayout/components/Navbar/components/Feedback';

export const Content: FC<RefsProps> = ({
    headingRef,
    telegramRef,
    ecomRef,
    individualConditionsRef,
    aboutUsRef,
}) => {
    return (
        <Fragment>
            <SlickStyle />
            <StyledContainer>
                <Heading refProp={headingRef} />
                <Intro />
                <TelegramAdvantages />
                <TelegramMiniApps refProp={telegramRef} />
                <PathToPurchase />
                <EcomOpportunities refProp={ecomRef} />
                <OurClients />
                <IndividualConditions refProp={individualConditionsRef} />
                <AboutUs refProp={aboutUsRef} />
            </StyledContainer>
            <FloatButton.BackTop />
        </Fragment>
    );
};

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1600px;
    height: auto;
    padding: 0 20px;
    background: #ffffff;
    margin: 0 auto;
    z-index: 1;

    @media (max-width: 992px) {
        padding: 0 20px;
    }
`;
