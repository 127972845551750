import React, { useState } from 'react';
import { App, Button, Divider, Flex, Input, Space, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLocation } from 'react-router';

import { HeaderScenarios } from '../../../communities/components/HeaderScenarios/HeaderScenarios';
import { Price } from '../../../courses/components/Author/containers/post/components/Price';
import { ProductUploader } from '../../../courses/components/Author/components/ProductUploader';
import { BuyableSwitcher } from '../../../courses/components/Author/components/BuyableSwitcher';
import { DescriptionEditor } from '../../../courses/components/Author/components/DescriptionEditor';
import { IProducts } from '../../interfaces/products.interface';
import { ProductStatusSwitcher } from '../../components/ProductStatusSwitcher';
import { CANCEL, SAVE } from '../../../../shared/contants/actions';
import { OldPrice } from '../../components/OldPrice';
import { ProductCategoriesSelector } from '../Categories/ProductCategoriesSelector';

const inputSize = 260;

interface IProductForm {
    readonly onSave: (values: IProducts) => Promise<void>;
    readonly initialValues: IProducts;
    readonly loading?: boolean;
    readonly shopId: number;
    readonly isDisableEditProduct?: boolean;
}
export const ProductForm: React.FC<IProductForm> = props => {
    const { pathname } = useLocation();
    const { type } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const location = useLocation();
    const isLinkPage = /\/links-create$|\/links-edit\//.test(location.pathname);

    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);

    const { onSave, loading, initialValues, shopId, isDisableEditProduct } =
        props;

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    const disabled =
        loading ||
        (!formik.values.price && !isLinkPage) ||
        (!formik.values.link && isLinkPage) ||
        formik.values.title === '' ||
        formik.values.images?.length < 1 ||
        loadingDeleteImage;

    const isEdit = pathname.includes('edit');

    const onChange = (value: string) => {
        if (value.match(/^[ ]+$/)) {
            return notification.error({
                message: 'Название товара обязательно для заполнения',
            });
        }

        if (value.length > 100) {
            return notification.error({
                message: 'Название товара не может быть длиннее 100 символов',
            });
        }

        if (value.length === 0) {
            formik.setFieldValue('title', value);
        } else formik.setFieldValue('title', value);
    };

    const handlerClearTitle = () => {
        formik.setFieldValue('title', '');
    };

    const renderTitle = (type: string) => {
        switch (type) {
            case 'products':
                return 'Товар';
            case 'links':
                return 'Витрина';
            case 'product-order':
                return 'Заявка';
            default:
                return 'Товар';
        }
    };

    return (
        <Flex vertical gap={'middle'}>
            <Flex vertical gap={8}>
                <Typography.Title
                    level={1}
                    style={{ margin: 0, marginBottom: '0' }}
                >
                    {renderTitle(type)}
                </Typography.Title>

                {type === 'product-order' && (
                    <Typography.Text>
                        Дает возможность оставить клиенту заявку на
                        индивидуальный товар. При оформлении заявки покупатель
                        указывает имя, телефон и почту для связи. Этот товар
                        нельзя добавить в корзину, а так же купить через
                        магазин. Отображение цены всегда “от”.
                    </Typography.Text>
                )}
            </Flex>

            <HeaderScenarios
                goBack={() => navigate(-1)}
                inputValue={formik?.values?.title || ''}
                onChangeHeaderInput={({ target }) => {
                    onChange(target.value);
                }}
                tooltip={formik?.values.title}
                onExtraEdit={!isEdit ? handlerClearTitle : null}
                withoutEdit={isDisableEditProduct}
            />

            <ProductUploader
                loadingDeleteImage={loadingDeleteImage}
                setLoadingDeleteImage={setLoadingDeleteImage}
                isEdit={isEdit}
                formik={formik}
                type={'images'}
                isDisabled={isDisableEditProduct}
            />

            {type === 'links' && (
                <Flex gap={16} style={{ maxWidth: '578px' }} vertical>
                    <Divider style={{ margin: 0 }} />
                    <Flex gap={8} vertical>
                        <Typography.Text>Ссылка на товар</Typography.Text>
                        <Input
                            value={formik?.values?.link}
                            placeholder='Укажите ссылку'
                            onChange={event => {
                                formik.setFieldValue(
                                    `link`,
                                    event.target.value
                                );
                            }}
                        />
                    </Flex>
                </Flex>
            )}

            <DescriptionEditor
                formik={formik}
                isDisabled={isDisableEditProduct}
            />

            <Price
                formik={formik}
                width={inputSize}
                type={type}
                isDisabled={isDisableEditProduct}
            />

            {type === 'links' ||
                (type === 'products' && (
                    <OldPrice
                        formik={formik}
                        width={inputSize}
                        isDisabled={isDisableEditProduct}
                    />
                ))}

            {type === 'products' && (
                <BuyableSwitcher
                    shopId={shopId}
                    formik={formik}
                    width={inputSize}
                    isDisabled={isDisableEditProduct}
                />
            )}

            <ProductStatusSwitcher
                formik={formik}
                width={inputSize}
                isDisabled={isDisableEditProduct}
            />

            <Flex gap={8} vertical>
                <Typography.Text>Категория товара</Typography.Text>
                <ProductCategoriesSelector
                    shopId={shopId}
                    formik={formik}
                    width={inputSize}
                    isDisabled={isDisableEditProduct}
                />
            </Flex>

            <Space wrap style={{ marginTop: '2rem' }}>
                <Button
                    disabled={disabled || isDisableEditProduct}
                    onClick={() => formik.submitForm()}
                    type={'primary'}
                >
                    {SAVE}
                </Button>
                <Button onClick={() => navigate(-1)}>{CANCEL}</Button>
            </Space>
        </Flex>
    );
};
