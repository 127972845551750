import React from 'react';

interface ArrowIconProps {
    rotate?: number;
    w?: number;
    h?: number;
    className?: string;
    color?: string;
}

export const Arrow = (props: ArrowIconProps) => {
    const { rotate, w, h, className, color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={w || 24}
            style={{
                transform: `rotate(${rotate}deg)`,
            }}
            height={h || 24}
            viewBox='0 0 24 24'
            fill='none'
            className={className}
        >
            <path
                d='M5 12H19M19 12L12 5M19 12L12 19'
                stroke={color ? color : '#1F2937'}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
