import React from 'react';

import { ProductList } from '../../views/Products/ProductList';
import { ProductTabs } from '../../views/Products/ProductTabs';
import { useList } from '../../hooks/useList';

export const ProductsContainer = () => {
    const { list, pagination, isLoading, updateFilters } = useList();

    return (
        <>
            <ProductTabs updateFilters={updateFilters} />
            <ProductList
                list={list.items || []}
                pagination={pagination}
                isLoading={isLoading}
            />
        </>
    );
};
