import React from 'react';
import { Button, Card, Flex, List, Space, Tooltip, Typography } from 'antd';
import Icon, { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ProductImage } from '../../components/Product/ProductImage';
import { getSrc } from '../../../../utils/common/getSrc';
import {
    ProductTitle,
    ProductTitleType,
} from '../../components/Product/ProductTitle';
import { PurifiedHtml } from '../../../../shared/ui/PurifyHtml';
import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { ProductProps } from '../../interfaces/products.interface';
import { useTheme } from '../../../../hooks/useTheme';
import { ArchiveSvg } from '../../components/IconArchive';
import { UnarchiveSvg } from '../../components/IconUnarchive';
import { Price } from '../../components/Product/Price';
import { getShopState } from '../../store/shopSlice';
import { useManageProducts } from '../../hooks/useManageProducts';

interface ProductListProps {
    readonly list: ProductProps[];
    readonly pagination: any;
    readonly isLoading: boolean;
}
export const ProductList = (props: ProductListProps) => {
    const { list, pagination, isLoading } = props;

    const { id, type } = useParams();
    const { boxShadow, colorPrimary } = useTheme();
    const { price_from } = useSelector(getShopState);
    const { changeProductStatus, deleteProduct, isLoadingDelete } =
        useManageProducts();
    const { manageProduct } = useManageProducts();

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 4,
                xl: 4,
                xxl: 4,
            }}
            loading={isLoading}
            dataSource={list}
            renderItem={product => (
                <List.Item>
                    <StyledCard
                        style={{ boxShadow }}
                        loading={isLoading}
                        key={product.id}
                        type='inner'
                        cover={
                            <ProductImage
                                loading={isLoading}
                                src={getSrc(
                                    `/storage/${product?.images?.[0]?.file}`
                                )}
                                gutter={1}
                                type={'web'}
                                categoryName={product.category_name}
                                style={{
                                    opacity: product.status === 3 ? '.3' : 1,
                                }}
                            />
                        }
                    >
                        <div
                            style={{
                                minHeight: 64,
                                marginBottom: '0.5rem',
                            }}
                        >
                            <ProductTitle
                                title={product.title}
                                type={ProductTitleType.page}
                            />
                        </div>

                        <StyledDescription
                            ellipsis={{
                                rows: 2,
                            }}
                        >
                            <StyledClearDescription>
                                <PurifiedHtml
                                    dirtyHtml={product?.description}
                                />
                            </StyledClearDescription>
                        </StyledDescription>

                        <Flex justify={'space-between'} align={'center'}>
                            {product.status === 3 ? (
                                <Typography.Paragraph
                                    ellipsis
                                    strong
                                    style={{
                                        color: colorPrimary,
                                        marginBottom: 0,
                                    }}
                                >
                                    Нет в наличии
                                </Typography.Paragraph>
                            ) : (
                                <Flex
                                    gap={'0 8px'}
                                    align={'baseline'}
                                    style={{ flexWrap: 'wrap' }}
                                >
                                    <Price.Current
                                        isPriceFrom={price_from}
                                        price={product.price}
                                    />
                                    <Price.Old price={product.old_price} />
                                </Flex>
                            )}
                            <Space>
                                <Tooltip
                                    key={product.id}
                                    title={
                                        product.status === (1 || 3)
                                            ? 'Добавить в архив'
                                            : 'Убрать из архива'
                                    }
                                    destroyTooltipOnHide
                                >
                                    <StyledButton
                                        onClick={() =>
                                            changeProductStatus(
                                                product.id,
                                                product.status
                                            )
                                        }
                                    >
                                        <Icon
                                            component={
                                                product.status === (1 || 3)
                                                    ? ArchiveSvg
                                                    : UnarchiveSvg
                                            }
                                        />
                                    </StyledButton>
                                </Tooltip>

                                <ConfirmationDialog
                                    activator={
                                        <StyledButton
                                            disabled={isLoadingDelete}
                                        >
                                            <DeleteOutlined />
                                        </StyledButton>
                                    }
                                    onConfirm={() => deleteProduct(product.id)}
                                    title='Удалить?'
                                    description={`Вы уверены что хотите удалить ${product.title}?`}
                                    withCheckbox
                                />

                                <StyledButton
                                    disabled={isLoading || isLoadingDelete}
                                    onClick={() =>
                                        manageProduct(type, +id, +product.id)
                                    }
                                >
                                    <EditOutlined />
                                </StyledButton>
                            </Space>
                        </Flex>
                    </StyledCard>
                </List.Item>
            )}
            pagination={pagination}
        />
    );
};

const StyledCard = styled(Card)`
    width: 100%;
    margin-top: 0.5rem;
`;

const StyledDescription = styled(Typography.Paragraph)`
    margin-bottom: 1.5rem !important;
    min-height: 44px;
`;

const StyledClearDescription = styled.span`
    & p {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
`;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
`;
