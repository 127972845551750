import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import StaticText from 'shared/layouts/LandingLayout/content.json';

import { SubTitle, Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { Darts } from '../../../ui/Icons/landing/Darts';
import PaymentTinkoff from '../../../../static/images/payment_tinkoff.svg';
import PaymentMC from '../../../../static/images/payment_mc.svg';
import PaymentMIR from '../../../../static/images/payment_mir.svg';
import PaymentVISA from '../../../../static/images/payment_visa.svg';
import { PhoneInHand } from '../../../ui/Icons/landing/PhoneInHand';
import { Devices } from '../../../ui/Icons/landing/Devices';
import { Handshake } from '../../../ui/Icons/landing/Handshake';

export const images = [
    {
        src: PaymentTinkoff,
        alt: 'Tinkoff',
    },

    {
        src: PaymentMC,
        alt: 'Master Card',
    },

    {
        src: PaymentMIR,
        alt: 'MIR',
    },

    {
        src: PaymentVISA,
        alt: 'VISA',
    },
];

export const TelegramMiniApps: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();
    const { hash } = useLocation();

    useLayoutEffect(() => {
        if (refProp && hash === '#create-store') {
            setTimeout(() => {
                refProp.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                });
            }, 500);
        }
    }, [refProp]);

    const renderImage = name => {
        switch (name) {
            case 'PhoneInHand':
                return (
                    <StyledIcon style={{ marginLeft: '11px' }}>
                        <PhoneInHand color={'#FDA2F6'} />
                    </StyledIcon>
                );

            case 'Darts':
                return (
                    <StyledIcon
                        style={{ marginLeft: '4px', marginTop: '-7px' }}
                    >
                        <Darts color={'#FDA2F6'} />
                    </StyledIcon>
                );

            case 'Devices':
                return (
                    <StyledIcon style={{ marginLeft: '3px' }}>
                        <Devices color={'#FDA2F6'} />
                    </StyledIcon>
                );

            case 'Handshake':
                return (
                    <StyledIcon style={{ marginLeft: '2px', marginTop: '5px' }}>
                        <Handshake color={'#FDA2F6'} />
                    </StyledIcon>
                );

            default:
                return <></>;
        }
    };

    return (
        <Container
            maxWidth={1118}
            marginTop={100}
            mobileMarginTop={41}
            refProp={refProp}
        >
            <SubTitle
                id={'telegram-advantages'}
                type='primary'
                text={StaticText.telegramMiniApps.title}
            />

            <StyledOptions>
                {StaticText.telegramMiniApps.options.map((option, index) => {
                    const titleLines = option.title.split('\n');
                    console.warn('titleLines  = ', titleLines);

                    return (
                        <StyledOptionWrapper key={index}>
                            {renderImage(option.imageName)}
                            <StyledOptionShadow key={index}>
                                <StyledOption $bigCard={option.isBigCard}>
                                    <StyledOptionTitle>
                                        {titleLines.map((line, lineIndex) => (
                                            <div key={`${index}${lineIndex}`}>
                                                {line}
                                            </div>
                                        ))}
                                    </StyledOptionTitle>

                                    <StyledDescription>
                                        {option.description}
                                    </StyledDescription>
                                </StyledOption>
                            </StyledOptionShadow>
                        </StyledOptionWrapper>
                    );
                })}
            </StyledOptions>
        </Container>
    );
};

const StyledOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 62px 0;
    margin-top: 65px;
    justify-content: space-around;
    @media (max-width: 920px) {
        gap: 32px 0;
        margin-top: 32px;
    }
`;

const StyledOptionWrapper = styled.div`
    position: relative;
    flex: 0 0 471px;
    @media (max-width: 920px) {
        flex: 0 0 100%;
    }
`;

const StyledOptionShadow = styled.div`
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
`;

const StyledOption = styled.div<{ $bigCard: boolean }>`
    background: white;
    position: relative;
    clip-path: ${props =>
        props.$bigCard
            ? `path("M11.9961 38.1606C11.9961 21.5921 25.4276 8.16064 41.9961 8.16064H352.705C360.437 8.16064 366.705 14.4287 366.705 22.1606V38.8544C366.705 55.4229 380.136 68.8544 396.705 68.8544H410.996C418.728 68.8544 424.996 75.1224 424.996 82.8544V272.161C424.996 288.729 411.565 302.161 394.996 302.161H41.9961C25.4276 302.161 11.9961 288.729 11.9961 272.161V38.1606Z")`
            : `path(
                'M11.9961 38C11.9961 21.4314 25.4276 8 41.9961 8H352.705C360.437 8 366.705 14.268 366.705 22V38.6402C366.705 55.1367 380.024 68.5382 396.52 68.6396L411.082 68.7292C418.78 68.7765 424.996 75.0305 424.996 82.7289V245C424.996 261.569 411.565 275 394.996 275H41.9961C25.4276 275 11.9961 261.569 11.9961 245L11.9961 38Z'
        )`};

    width: 424px;
    height: ${props => (props.$bigCard ? `306px` : `276px`)};
    padding: 41px 24px 24px 38px;

    display: flex;
    gap: 32px;
    flex-direction: column;
    @media (max-width: 920px) {
        clip-path: none;
        width: 100%;
        background: none;
        padding: 0;
        height: auto;
        gap: 0;
    }
`;

const StyledOptionTitle = styled.h4`
    color: #2357ff;
    padding-right: 16px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    margin: 0;

    div {
        font-weight: 700;
    }

    @media (max-width: 920px) {
        font-size: 16px;
        line-height: 22px;
        padding: 16px 16px 8px 16px;
        margin-right: 68px;
        background: #ffffff;
        border-top-left-radius: 30px;
        border-top-right-radius: 14px;
        position: relative;
    }

    &:before {
        display: none;
        content: '';
        width: 32px;
        height: 32px;
        //transform: rotate(-90deg);
        background-color: #ffffff;
        position: absolute;
        z-index: 1;
        right: -31px;
        bottom: -1px;
        clip-path: path(
            'm 0.05511438,2.0136258 c 0,6.901815 2.33067402,13.2592852 6.24792002,18.3282852 5.4864946,7.099641 14.0853366,11.671715 23.7520796,11.671715 H 0.05391438 Z'
        );
        @media (max-width: 920px) {
            display: block;
        }
    }
`;

const StyledDescription = styled.p`
    color: #1f2937;

    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    margin: 0;
    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
        padding: 8px 16px 16px 16px;
        background: #ffffff;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: 14px;
    }
`;

const StyledIcon = styled.div`
    position: absolute;
    top: -29px;
    left: 374px;
    z-index: 2;

    svg {
        max-width: 100%;
    }

    @media (max-width: 920px) {
        top: -24px;
        right: -3px;
        left: initial;
        width: 62px;
    }
`;
