import React from 'react';

export const Divan = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='141'
            height='34'
            viewBox='0 0 141 34'
            fill='none'
        >
            <path
                d='M13.1725 15.1507C12.2748 13.682 10.8524 12.7699 8.73681 12.7699C4.42968 12.7699 1.05469 16.493 1.05469 21.0116C1.05469 25.6894 4.42968 29.4126 8.73681 29.4126C10.8524 29.4126 12.2748 28.5004 13.1725 27.0124V29.0625H18.5082V3.73251H13.1725V15.1507ZM9.79752 24.7666C7.6761 24.7666 6.45468 23.5574 6.45468 21.0753C6.45468 18.5932 7.6761 17.4159 9.79752 17.4159C11.8547 17.4159 13.1725 18.5932 13.1725 21.0753C13.1725 23.5574 11.8547 24.7666 9.79752 24.7666Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M40.4945 24.7348H40.4302L36.6052 13.1518H30.916L36.6696 29.0627H44.1588L49.8802 13.1518H44.2873L40.4945 24.7348Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M25.869 3.35062C23.3941 3.35062 21.7227 5.00535 21.7227 7.23286C21.7227 9.46037 23.3941 11.1151 25.869 11.1151C28.3119 11.1151 29.9512 9.46037 29.9512 7.23286C29.9512 5.00535 28.3119 3.35062 25.869 3.35062Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M28.5057 13.1518H23.1699V29.0627H28.5057V13.1518Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M63.026 15.1513C62.1283 13.6826 60.7059 12.7704 58.5903 12.7704C54.2832 12.7704 50.9082 16.4936 50.9082 21.0123C50.9082 25.6901 54.2832 29.4131 58.5903 29.4131C60.7059 29.4131 62.1283 28.501 63.026 27.013V29.0631H68.3617V13.1524H63.026V15.1513ZM59.651 24.7672C57.5295 24.7672 56.3082 23.5579 56.3082 21.0759C56.3082 18.5938 57.5295 17.4164 59.651 17.4164C61.7081 17.4164 63.026 18.5938 63.026 21.0759C63.026 23.558 61.7081 24.7672 59.651 24.7672Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M134.61 13.1518V21.2981C134.61 23.5257 133.774 24.544 131.974 24.544C130.11 24.544 129.306 23.5257 129.306 21.2981V13.1518H123.971V21.33C123.971 26.7715 127.184 29.4763 131.974 29.4763C136.698 29.4763 139.945 26.7715 139.945 21.33V13.1518H134.61Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M116.545 12.7704C114.102 12.7704 112.398 14.4889 112.398 16.7799C112.398 19.1029 114.102 20.7894 116.545 20.7894C118.956 20.7894 120.627 19.1029 120.627 16.7799C120.627 14.4889 118.956 12.7704 116.545 12.7704Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M83.2454 12.8333C80.9312 12.8333 79.3239 13.6607 78.1989 15.2517V13.1515H72.8633V29.0623H78.1989V20.5977C78.1989 18.4657 79.0668 17.4474 81.0918 17.4474C83.1168 17.4474 83.9847 18.4657 83.9847 20.5977V29.0623H89.3204V20.5977C89.3204 15.5064 86.3954 12.8333 83.2454 12.8333Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M110.984 13.1518H105.648V29.0627H110.984V13.1518Z'
                fill={color ? color : '#4A4DD0'}
            />
            <path
                d='M97.5168 20.8207C94.9454 20.8207 93.1133 22.6663 93.1133 25.0847C93.1133 27.535 94.9454 29.3489 97.5168 29.3489C100.088 29.3489 101.856 27.535 101.856 25.0847C101.856 22.6663 100.088 20.8207 97.5168 20.8207Z'
                fill={color ? color : '#4A4DD0'}
            />
        </svg>
    );
};
