import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyReadProductsQuery } from '../services/products';
import { ProductsFilters } from '../interfaces/products.interface';
import { selectInitialFilters } from '../utils/filters';

export const useList = () => {
    const { id, type } = useParams();

    const [filters, setFilters] = useState({
        shop_id: +id,
        ...selectInitialFilters(type),
    });

    const [getProducts, { data: list = { items: [], total: 0 }, isLoading }] =
        useLazyReadProductsQuery();

    useEffect(() => {
        setFilters({
            shop_id: +id,
            ...selectInitialFilters(type),
        });
    }, [type]);

    useEffect(() => {
        if (filters) {
            getProducts(filters, true);
        }
    }, [filters]);

    const updateFilters = (update: ProductsFilters) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters(null);
        }
    };

    const pagination = {
        defaultCurrent: filters.page,
        current: filters.page,
        showTitle: true,
        defaultPageSize: filters.limit,
        onChange: (page: number, pageSize: number) => {
            updateFilters({
                offset: (page - 1) * pageSize,
                limit: pageSize,
                page: page,
            });
        },
        total: list.total,
        showTotal: (total: number) => `Товаров: ${total}`,
    };

    return { list, updateFilters, pagination, isLoading };
};
