import React, { useContext } from 'react';
export const DoubleQuotes = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27'
            height='20'
            viewBox='0 0 26 20'
            fill='none'
        >
            <path
                d='M19.3371 10.8759C17.8403 10.8759 16.5849 10.3406 15.571 9.27009C14.5088 8.24819 13.9777 6.95859 13.9777 5.40147C13.9777 3.89289 14.5088 2.60338 15.571 1.53285C16.6333 0.510947 17.9852 -7.0068e-07 19.6268 -5.57167e-07C21.4615 -3.96771e-07 22.9824 0.656936 24.1894 1.97081C25.3965 3.28468 26 5.13377 26 7.51826C26 10.9246 25.0826 13.7469 23.2479 15.9854C21.4132 18.2725 19.0474 19.6107 16.1504 20L16.1504 16.0584C17.3092 15.7177 18.2024 15.0608 18.8301 14.0876C19.4578 13.163 19.7716 12.0924 19.7716 10.8759L19.3371 10.8759ZM5.35932 10.8759C3.8626 10.8759 2.60724 10.3406 1.59331 9.27009C0.531137 8.24819 1.14012e-06 6.95859 1.27624e-06 5.40147C1.40813e-06 3.89289 0.531137 2.60338 1.59331 1.53285C2.65558 0.510946 4.00745 -1.92265e-06 5.64902 -1.77914e-06C7.48377 -1.61874e-06 9.00466 0.656935 10.2117 1.97081C11.4188 3.28468 12.0223 5.13377 12.0223 7.51826C12.0223 10.9246 11.1049 13.7469 9.27018 15.9854C7.43552 18.2725 5.06963 19.6107 2.1727 20L2.1727 16.0584C3.33147 15.7177 4.22472 15.0608 4.85236 14.0876C5.48009 13.163 5.79386 12.0924 5.79386 10.8759L5.35932 10.8759Z'
                fill={color ? color : '#000000'}
            />
        </svg>
    );
};
