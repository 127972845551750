import {
    ProductsFilters,
    ProductsTypes,
} from '../interfaces/products.interface';

export const selectInitialFilters = type => {
    switch (type) {
        case 'product-order':
            return initialFiltersProductOrder;
        case 'links':
            return InitialFiltersLinks;
        case 'archive':
            return InitialFiltersArchived;
        case 'products':
            return initialFilters;
        default:
            return initialFilters;
    }
};

export const initialFilters: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [2],
    page: 1,
    type: ProductsTypes.product,
};

export const InitialFiltersArchived: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [1, 3],
    page: 1,
    type: ProductsTypes.product,
};
export const InitialFiltersLinks: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [],
    page: 1,
    type: ProductsTypes.link,
};

export const initialFiltersProductOrder: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [2],
    page: 1,
    type: ProductsTypes.productOrder,
};
