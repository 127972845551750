import React, { useContext } from 'react';

export const Devices = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='94'
            height='95'
            viewBox='0 0 94 95'
            fill='none'
        >
            <path
                d='M70.293 45.549V24.2791C70.293 22.2086 69.4705 20.223 68.0064 18.7589C66.5424 17.2949 64.5568 16.4724 62.4863 16.4724H15.6465C13.576 16.4724 11.5904 17.2949 10.1264 18.7589C8.66233 20.223 7.83984 22.2086 7.83984 24.2791L7.83984 51.6023C7.83984 53.6727 8.66233 55.6584 10.1264 57.1224C11.5904 58.5864 13.576 59.4089 15.6465 59.4089H59.3081M39.0664 75.0222V59.5651V71.8605M27.3564 75.0222H46.873M70.293 47.699H78.0996C82.4111 47.699 85.9062 51.1941 85.9062 55.5056V78.9255C85.9062 83.237 82.4111 86.7322 78.0996 86.7322H70.293C65.9815 86.7322 62.4863 83.237 62.4863 78.9255V55.5056C62.4863 51.1941 65.9815 47.699 70.293 47.699Z'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
