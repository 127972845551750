import React from 'react';

export const CrossIcon = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                d='M18 6L6 18M6 6L18 18'
                stroke={color ? color : '#000000'}
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
