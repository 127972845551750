import React, { useEffect, useRef, useState } from 'react';
import { Input, Form, Col, Button, Row, Flex } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import styled from 'styled-components';

type SendDemoEmailFormProps = {
    readonly isLoading: boolean;
    readonly onCancel: () => void;
    readonly onSubmit: (values: any) => void;
};

type FormValues = {
    fb_message: string;
};

export const SendDemoEmailForm: React.FC<SendDemoEmailFormProps> = props => {
    const inputRef = useRef<TextAreaRef>();
    const [submittable, setSubmittable] = useState<boolean>(false);

    const [form] = Form.useForm();

    const values = Form.useWatch([], form);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);

    const handleSubmit = () => {
        props.onSubmit(values);
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <Form
            scrollToFirstError
            form={form}
            onSubmit={handleSubmit}
            onCancel={props.onCancel}
            validateOnMount
            validateOnBlur
        >
            <Flex vertical>
                <StyledFields>
                    <div>
                        <StyledLabel>Имя</StyledLabel>
                        <Form.Item
                            label={false}
                            name='name'
                            hasFeedback
                            style={{ marginBottom: 0 }}
                            rules={[
                                {
                                    pattern: new RegExp(
                                        /^[a-zA-Zа-яёА-ЯЁ'][a-zA-Zа-яёА-ЯЁ' ]+[a-zA-Zа-яёА-ЯЁ']?$/u
                                    ),
                                    message: 'Это не похоже на имя',
                                },
                                {
                                    required: true,
                                    message: 'Укажите ваше имя',
                                },
                                {
                                    max: 100,
                                    message:
                                        'Имя не может быть больше 100 символов',
                                },
                                {
                                    min: 2,
                                    message:
                                        'Имя не может быть короче 2 символов',
                                },
                            ]}
                        >
                            <StyledInput
                                min={2}
                                max={100}
                                ref={inputRef}
                                size={'large'}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <StyledLabel>Телефон</StyledLabel>
                        <Form.Item
                            name='phone'
                            hasFeedback
                            style={{ marginBottom: 0 }}
                            rules={[
                                {
                                    pattern: new RegExp(/^\d+$/),
                                    message: 'Нужно указать только цифры',
                                },
                                {
                                    required: true,
                                    message: 'Укажите телефон',
                                },
                            ]}
                        >
                            <StyledInput
                                maxLength={10}
                                prefix={'+7'}
                                size={'large'}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <StyledLabel>Название проекта</StyledLabel>

                        <Form.Item
                            label={false}
                            name='text'
                            hasFeedback
                            style={{ marginBottom: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Укажите название проекта',
                                },
                                {
                                    max: 300,
                                    message:
                                        'Название не может быть больше 300 символов',
                                },
                                {
                                    min: 2,
                                    message:
                                        'Название не может быть короче 2 символов',
                                },
                            ]}
                        >
                            <StyledInput min={2} max={100} size={'large'} />
                        </Form.Item>
                    </div>
                </StyledFields>

                <StyledActions Flex gap={40} justify={'center'}>
                    <StyledPrimaryButton
                        block
                        onClick={handleSubmit}
                        type='primary'
                        disabled={!submittable}
                    >
                        Отправить
                    </StyledPrimaryButton>

                    <StyledSecondaryButton
                        onClick={() => {
                            props.onCancel && props.onCancel();
                        }}
                        block
                    >
                        Отмена
                    </StyledSecondaryButton>
                </StyledActions>
            </Flex>
        </Form>
    );
};

const StyledFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
    @media (max-width: 920px) {
        gap: 16px;
        margin-bottom: 24px;
    }
`;

const StyledActions = styled.div`
    display: flex;
    gap: 12px 40px;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 920px) {
        flex-direction: column;
    }
`;

const StyledLabel = styled.label`
    color: #1f2937;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

const StyledInput = styled(Input)`
    border-radius: 30px;
    margin-top: 8px;
    padding: 3px 16px;

    @media (max-width: 920px) {
        padding: 8px 16px;
    }
`;

const StyledPrimaryButton = styled(Button)`
    background-color: #2357ff;
    padding: 18px;
    width: auto !important;
    height: auto;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @media (max-width: 920px) {
        width: 100%;
        padding: 11px;
    }
`;

const StyledSecondaryButton = styled(Button)`
    padding: 18px;
    width: auto !important;
    height: auto;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @media (max-width: 920px) {
        width: 100%;
        padding: 11px;
    }
`;
