import React, { useContext } from 'react';

export const Darts = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='96'
            height='96'
            viewBox='0 0 96 96'
            fill='none'
        >
            <path
                d='M85.0214 26.1716C88.9055 32.7718 91.1346 40.4727 91.1346 48.6969C91.1346 73.1648 71.4042 93 47.0654 93C22.7266 93 2.99609 73.1648 2.99609 48.6969C2.99609 24.2291 22.7266 4.39392 47.0654 4.39392C55.2146 4.39392 62.8472 6.61759 69.3955 10.4943'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
            />
            <path
                d='M75.8785 48.696C75.8785 64.7162 62.9643 77.676 47.0672 77.676C31.1701 77.676 18.2559 64.7162 18.2559 48.696C18.2559 32.6758 31.1701 19.7159 47.0672 19.7159C62.9643 19.7159 75.8785 32.6758 75.8785 48.696Z'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
            />
            <path
                d='M59.8117 48.696C59.8117 55.7964 54.0903 61.5252 47.066 61.5252C40.0417 61.5252 34.3203 55.7964 34.3203 48.696C34.3203 41.5957 40.0417 35.8668 47.066 35.8668C54.0903 35.8668 59.8117 41.5957 59.8117 48.696Z'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
            />
            <path
                d='M46.5098 48.9354L84.5823 10.6606'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M67.3574 27.7264L81.9302 28.7583C82.0735 28.7684 82.2142 28.7164 82.3165 28.6156L92.2673 18.8049C92.5636 18.5128 92.3942 18.0076 91.9816 17.9532L80.2802 16.4104'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
                strokeLinecap='round'
            />
            <path
                d='M67.6227 28.7742L66.596 14.1212C66.586 13.9789 66.6373 13.8391 66.7369 13.7371L76.491 3.73833C76.7827 3.43934 77.2907 3.60861 77.3447 4.0228L78.8789 15.7828'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
                strokeLinecap='round'
            />
        </svg>
    );
};
