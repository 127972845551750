import React from 'react';

export const Rocket = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='81'
            height='80'
            viewBox='0 0 81 80'
            fill='none'
        >
            <path
                d='M35.4191 58.2393L22.3979 45.2039C22.1404 44.946 22.0455 44.5603 22.1491 44.2109C29.3797 19.8232 41.6739 12.6966 71.7354 7.70682C72.3731 7.60098 72.9517 8.13254 72.8986 8.77674C70.5222 37.5933 61.9835 48.4713 36.4955 58.4607C36.1266 58.6053 35.6991 58.5197 35.4191 58.2393Z'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
            />
            <path
                d='M58.9139 26.8012C58.9139 29.7462 56.5293 32.1296 53.5927 32.1296C50.656 32.1296 48.2715 29.7462 48.2715 26.8012C48.2715 23.8563 50.656 21.4728 53.5927 21.4728C56.5293 21.4728 58.9139 23.8563 58.9139 26.8012Z'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
            />
            <path
                d='M45.0547 54.5676L45.0547 71.2921C45.0547 71.7377 45.5935 71.9607 45.9084 71.6455L59.042 58.4998C59.1357 58.4061 59.1883 58.279 59.1883 58.1464V46.1188'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
            />
            <path
                d='M25.9163 35.6898L9.20675 35.6898C8.76141 35.6898 8.53827 35.1514 8.85303 34.8364L21.9875 21.6898C22.0813 21.5959 22.2086 21.5432 22.3413 21.5432L34.3574 21.5432'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
            />
            <path
                d='M29.2662 62.0079L20.2188 71.0624'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M18.481 51.2548L9.43359 60.3093'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
                strokeLinecap='round'
            />
            <path
                d='M23.0076 57.4806L9.43359 71.0624'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
                strokeLinecap='round'
            />
        </svg>
    );
};
