import React from 'react';

export const Person = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='81'
            height='80'
            viewBox='0 0 81 80'
            fill='none'
        >
            <path
                d='M40.5007 42.7485C49.7054 42.7485 57.1673 35.4175 57.1673 26.3743C57.1673 17.331 49.7054 10 40.5007 10C31.2959 10 23.834 17.331 23.834 26.3743C23.834 35.4175 31.2959 42.7485 40.5007 42.7485ZM40.5007 42.7485C47.5731 42.7485 54.3559 45.5088 59.3568 50.422C64.3578 55.3352 67.1673 61.999 67.1673 68.9474M40.5007 42.7485C33.4282 42.7485 26.6454 45.5088 21.6445 50.422C16.6435 55.3352 13.834 61.999 13.834 68.9474'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
