import React, { useContext } from 'react';

export const Handshake = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='92'
            height='92'
            viewBox='0 0 92 92'
            fill='none'
        >
            <path
                d='M42.1621 65.2889L49.8288 72.8722C50.5839 73.6191 51.4803 74.2116 52.4669 74.6158C53.4535 75.02 54.5109 75.228 55.5788 75.228C56.6467 75.228 57.7041 75.02 58.6907 74.6158C59.6772 74.2116 60.5737 73.6191 61.3288 72.8722C62.0839 72.1253 62.6829 71.2386 63.0915 70.2627C63.5002 69.2869 63.7105 68.241 63.7105 67.1847C63.7105 66.1284 63.5002 65.0825 63.0915 64.1066C62.6829 63.1308 62.0839 62.2441 61.3288 61.4972M53.6621 53.9139L63.2454 63.393C64.7704 64.9014 66.8388 65.7489 68.9954 65.7489C71.1521 65.7489 73.2205 64.9014 74.7454 63.393C76.2704 61.8846 77.1272 59.8388 77.1272 57.7055C77.1272 55.5723 76.2704 53.5264 74.7454 52.018L59.8721 37.3064C57.7159 35.1762 54.793 33.9797 51.7454 33.9797C48.6979 33.9797 45.775 35.1762 43.6188 37.3064L40.2454 40.643C38.7204 42.1514 36.6521 42.9989 34.4954 42.9989C32.3388 42.9989 30.2704 42.1514 28.7454 40.643C27.2204 39.1346 26.3637 37.0888 26.3637 34.9555C26.3637 32.8223 27.2204 30.7764 28.7454 29.268L39.5171 18.6134C43.014 15.1636 47.5744 12.9659 52.4762 12.3685C57.3781 11.771 62.3414 12.8078 66.5804 15.3147L68.3821 16.3764C70.0143 17.3508 71.955 17.6887 73.8254 17.3243L80.4954 15.9972M80.4954 12.2055L84.3288 53.9139H76.6621M11.4954 12.2055L7.66211 53.9139L32.5788 78.5597C34.1038 80.0681 36.1721 80.9155 38.3288 80.9155C40.4854 80.9155 42.5538 80.0681 44.0788 78.5597C45.6038 77.0513 46.4605 75.0054 46.4605 72.8722C46.4605 70.739 45.6038 68.6931 44.0788 67.1847M11.4954 15.9972H42.1621'
                stroke={color ? color : '#000000'}
                strokeWidth='6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
