import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';

import CreateSpodialStoreImage from 'static/landing/create-spodial-store.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';
import DurovPhoto from 'static/landing/DurovPhoto.png';
import PurpleBG1 from 'static/landing/PurpleBG1.png';
import PurpleBG2 from 'static/landing/PurpleBG2.png';

import { Container } from '../ui/Container';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { Gear } from '../../../ui/Icons/landing/Gear';
import { Talk } from '../../../ui/Icons/landing/Talk';
import { Person } from '../../../ui/Icons/landing/Person';
import { Rocket } from '../../../ui/Icons/landing/Rocket';
import { DemoEmail } from '../components/DemoEmail';

export const IndividualConditions: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();
    const { hash } = useLocation();

    useLayoutEffect(() => {
        if (refProp && hash === '#create-store') {
            setTimeout(() => {
                refProp.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                });
            }, 500);
        }
    }, [refProp]);

    const renderImage = name => {
        switch (name) {
            case 'Gear':
                return <Gear color={'#FDA2F6'} />;

            case 'Talk':
                return <Talk color={'#FDA2F6'} />;

            case 'Person':
                return <Person color={'#FDA2F6'} />;

            case 'Rocket':
                return <Rocket color={'#FDA2F6'} />;

            default:
                return <></>;
        }
    };

    return (
        <Container
            maxWidth={1065}
            marginTop={100}
            refProp={refProp}
            mobileMarginTop={41}
        >
            <StyledCard $background1={PurpleBG1} $background2={PurpleBG2}>
                <StyledTitle id={'telegram-advantages'}>
                    {StaticText.individualConditions.title}
                </StyledTitle>
                <StyledDescription>
                    {StaticText.individualConditions.description}
                </StyledDescription>

                <StyledOptions>
                    {StaticText.individualConditions.options.map(
                        (option, index) => (
                            <StyledOption key={`${option.title}${index}`}>
                                <StyledOptionIcon>
                                    {renderImage(option.image)}
                                </StyledOptionIcon>
                                <Flex vertical gap={16}>
                                    <StyledOptionTitle>
                                        {option.title}
                                    </StyledOptionTitle>
                                    <StyledOptionDescription>
                                        {option.description}
                                    </StyledOptionDescription>
                                </Flex>
                            </StyledOption>
                        )
                    )}
                </StyledOptions>
                <Flex justify={'center'}>
                    <DemoEmail
                        activator={
                            <StyledButton type='primary'>
                                {StaticText.heading.actions.buttonText1}
                            </StyledButton>
                        }
                    />
                </Flex>
            </StyledCard>
        </Container>
    );
};

const StyledCard = styled.div<{ $background1: string; $background2: string }>`
    padding: 32px 32px 46px 32px;
    background-color: #728cff;
    background-image: ${props =>
        `url(${props.$background1}), url(${props.$background2})`};
    background-position: 0 0, 100% 100%;
    background-repeat: no-repeat;
    border-radius: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    @media (max-width: 920px) {
        padding: 20px;
    }
`;

const StyledOptionIcon = styled.div`
    flex: 0 0 80px;
`;

const StyledTitle = styled.h2`
    color: #fff;

    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    margin: 0;

    @media (max-width: 920px) {
        font-size: 18px;
        line-height: 25px;
    }
`;

const StyledDescription = styled.div`
    color: #fff;

    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    margin-top: 10px;

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
    }
`;

const StyledOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 45px 78px;
    margin: 62px 37px 0;

    @media (max-width: 768px) {
        margin: 42px 0 0;
    }

    @media (max-width: 920px) {
        gap: 42px 78px;
    }
`;

const StyledOption = styled.div`
    flex: 1 0 45%;
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const StyledOptionTitle = styled.h4`
    color: #fff;

    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    @media (max-width: 920px) {
        font-size: 16px;
        line-height: 22px;
    }
`;

const StyledOptionDescription = styled.p`
    color: #fff;

    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
    }
`;

const StyledButton = styled(Button)`
    height: 67px;
    padding: 0 28px;
    background: rgb(253, 162, 246);
    border: none;
    border-radius: 100px;
    font-size: 20px;
    color: #1f2937;
    box-shadow: rgba(0, 0, 0, 0.15) 0 4px 12px 0;
    margin-top: 24px;

    &:hover {
        background: #ffe3fd !important;
        color: #1f2937 !important;
    }

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 16px;
        padding: 0 16px;
        height: 48px;
    }
`;

const StyledIcon = styled.div``;
