import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import type { ICreateFeedbackDto, ITelegramAccount } from '../interfaces';
import { IAdditionalFields } from '../interfaces/additionalFields.interface';
import { IUser } from '../interfaces';
import { ShopKeys } from '../../shops/interfaces/shop.interface';
import { SendDemoEmailProps } from '../interfaces/sendDemoEmail.interface';

export const userApi = createApi({
    reducerPath: 'user',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['User', 'BankCard'],
    endpoints: builder => ({
        readUser: builder.query<IUser, void>({
            query: () => ({
                url: '/user',
            }),
            transformResponse: (res: { data: any }) => res?.data,
            providesTags: ['User'],
        }),

        createFeedback: builder.mutation<void, ICreateFeedbackDto>({
            query: payload => ({
                url: '/feed-back',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['User'],
        }),

        createSendDemoEmail: builder.mutation<void, SendDemoEmailProps>({
            query: payload => ({
                url: '/send_demo_email',
                method: 'POST',
                body: payload,
            }),
        }),

        changePassword: builder.mutation<
            void,
            { password: string; password_confirmation: string }
        >({
            query: password => {
                return {
                    url: '/user/password/change',
                    method: 'POST',
                    body: password,
                };
            },
        }),
        listBankCards: builder.query<
            Array<{
                CardId: string;
                CardType: number;
                ExptDate: string;
                Pan: string;
                Status: string;
                RebillId: string;
            }>,
            void
        >({
            query: () => ({
                url: '/payment-cards',
            }),
            providesTags: ['BankCard'],
            transformResponse: (response: {
                data: Array<{
                    CardId: string;
                    CardType: number;
                    ExptDate: string;
                    Pan: string;
                    Status: string;
                    RebillId: string;
                }>;
            }) => {
                return response.data;
            },
        }),
        addBankCard: builder.mutation<any, void>({
            query: () => ({
                url: '/payment-cards',
                method: 'POST',
            }),
            invalidatesTags: ['BankCard'],
        }),
        deleteBankCard: builder.mutation<void, string>({
            query: cardId => ({
                url: '/payment-cards',
                method: 'DELETE',
                body: {
                    card_id: cardId,
                },
            }),
            invalidatesTags: ['BankCard'],
        }),
        deleteAccount: builder.mutation<void, void>({
            query: () => ({
                url: `/users`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
        updateUserAdditionalFields: builder.mutation<
            IAdditionalFields,
            IAdditionalFields
        >({
            query: (body: IAdditionalFields) => ({
                url: 'users/additional-fields',
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['User'],
        }),
        requestPhoneConfirmation: builder.mutation<
            { error: { status: number } },
            { code: string; phone: string }
        >({
            query: ({ code, phone }) => ({
                url: '/user/phone/send-confirm-code',
                method: 'POST',
                body: {
                    phone,
                    code: code,
                },
            }),
        }),
        confirmPhone: builder.mutation<void, { code: string; phone: string }>({
            query: ({ code, phone }) => ({
                url: '/user/phone/confirm',
                method: 'POST',
                body: {
                    phone,
                    sms_code: code,
                },
            }),
            invalidatesTags: ['User'],
        }),
        attachTelegram: builder.mutation<void, ITelegramAccount>({
            query: telegram => ({
                url: '/user/telegram/assign',
                method: 'POST',
                body: telegram,
            }),
            invalidatesTags: ['User'],
        }),
        detachTelegram: builder.mutation<void, number>({
            query: telegram_id => ({
                url: `/user/telegram/detach`,
                method: 'DELETE',
                body: {
                    telegram_id,
                },
            }),
            invalidatesTags: ['User'],
        }),
        createUnitPayKeys: builder.mutation<void, ShopKeys>({
            query: body => ({
                url: '/user/unitpay-key',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['User'],
        }),
        readUnitPayKeys: builder.query<ShopKeys, { shop_id: number }>({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipEmptyString: true,
                    skipNull: true,
                });
                return {
                    url: `/user/unitpay-key?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: ShopKeys }) => res?.data,
            providesTags: ['User'],
        }),
        deleteUnitPayKeys: builder.mutation<void, void>({
            query: () => ({
                url: `/user/unitpay-key`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
    }),
});

export const {
    useReadUserQuery,
    useLazyReadUserQuery,
    useCreateSendDemoEmailMutation,
    useCreateFeedbackMutation,
    useChangePasswordMutation,
    useListBankCardsQuery,
    useAddBankCardMutation,
    useDeleteBankCardMutation,
    useDeleteAccountMutation,
    useUpdateUserAdditionalFieldsMutation,
    useConfirmPhoneMutation,
    useRequestPhoneConfirmationMutation,
    useDetachTelegramMutation,
    useAttachTelegramMutation,
    useCreateUnitPayKeysMutation,
    useLazyReadUnitPayKeysQuery,
    useDeleteUnitPayKeysMutation,
} = userApi;
