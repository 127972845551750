import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
    children: React.ReactNode;
    refProp?: React.MutableRefObject<HTMLDivElement>;
    maxWidth?: number;
    marginTop?: number;
    mobileMarginTop?: number;
}

export const Container = (props: ContainerProps) => {
    const {
        children,
        maxWidth = 1200,
        marginTop = 24,
        mobileMarginTop,
        refProp,
    } = props;

    return (
        <StyledContainer
            $maxWidth={maxWidth}
            $marginTop={marginTop}
            $mobileMarginTop={mobileMarginTop}
            ref={refProp}
        >
            {children}
        </StyledContainer>
    );
};

const StyledContainer = styled.div<{
    $maxWidth: number;
    $marginTop: number;
    $mobileMarginTop: number;
}>`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: ${props => `${props.$maxWidth}px`};
    padding-top: ${props => `${props.$marginTop}px`};
    //width: calc(100% - 1rem);

    @media (max-width: 920px) {
        padding-top: ${props =>
            props.$mobileMarginTop
                ? `${props.$mobileMarginTop}px`
                : `${props.$marginTop}px`};
    }

    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
`;
