import React, { useRef } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

import { Navbar } from 'shared/layouts/LandingLayout/ui/Navbar';
import { Content } from 'shared/layouts/LandingLayout/ui/Content';

import { Footer } from '../../ui/Footer';

export const LandingLayout: React.FC = () => {
    const headingRef = useRef(null);
    const introRef = useRef(null);
    const contactsRef = useRef(null);
    const telegramRef = useRef(null);
    const ecomRef = useRef(null);
    const individualConditionsRef = useRef(null);
    const aboutUsRef = useRef(null);

    const blockProps = {
        headingRef: headingRef,
        introRef: introRef,
        contactsRef: contactsRef,
        telegramRef: telegramRef,
        ecomRef: ecomRef,
        individualConditionsRef: individualConditionsRef,
        aboutUsRef: aboutUsRef,
    };

    return (
        <StyledLayout>
            <Navbar {...blockProps} />
            <Content {...blockProps} />
            <Footer contactsRef={contactsRef} />
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)`
    background: #ffffff;
`;
