import React, { useContext } from 'react';

export const CheckIcon = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                d='M20 6L9 17L4 12'
                stroke={color ? color : '#000000'}
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
