import React, { FC } from 'react';
import styled from 'styled-components';

import ShopExample from 'static/landing/ShopExample.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';
import IntroBackground from 'static/landing/IntroBackground.png';

import { Container } from '../ui/Container';
import { ScreenProps } from '../types/landing.interface';

export const Intro: FC<ScreenProps> = ({ refProp }) => {
    return (
        <Container>
            <StyledWrapper>
                <StyledBackground $background={IntroBackground}>
                    <svg
                        width='1200'
                        // height='835'
                        viewBox='0 0 1200 835'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        style={{ maxWidth: '100%' }}
                    >
                        <rect
                            x='1200'
                            y='0.131836'
                            width='834'
                            height='1200'
                            transform='rotate(90 1200 0.131836)'
                            fill='url(#pattern0_407_7641)'
                        />
                        <rect
                            x='462'
                            y='161.395'
                            width='276'
                            height='500.653'
                            rx='30'
                            fill='white'
                        />
                        <rect
                            x='462.5'
                            y='161.895'
                            width='275'
                            height='499.653'
                            rx='29.5'
                            stroke='#999BFF'
                            strokeOpacity='0.5'
                        />
                        <rect
                            x='470.5'
                            y='171.828'
                            width='259'
                            height='479.786'
                            rx='22.5'
                            fill='url(#pattern1_407_7641)'
                            stroke='#DFDFDF'
                        />
                        <path
                            d='M205.467 237.883V264.008C205.467 280.577 218.898 294.008 235.467 294.008H302.613C314.682 294.008 324.467 303.792 324.467 315.862V315.862C324.467 327.932 334.251 337.716 346.321 337.716H470.467'
                            stroke='#1F2937'
                        />
                        <path
                            d='M334.27 122.893C334.27 106.324 320.838 92.8928 304.27 92.8928H151.477C143.745 92.8928 137.477 99.1608 137.477 106.893V110.444C137.477 117.071 132.104 122.444 125.477 122.444H106.467C98.735 122.444 92.4669 128.712 92.4669 136.444V207.884C92.4669 224.452 105.898 237.884 122.467 237.884H304.27C320.838 237.884 334.27 224.452 334.27 207.884V122.893Z'
                            fill='#2357FF'
                        />

                        <rect
                            x='292.463'
                            y='127.854'
                            width='73'
                            height='38.741'
                            rx='19.3705'
                            fill='#FDA2F6'
                        />
                        <rect
                            x='294.463'
                            y='129.841'
                            width='35'
                            height='34.7676'
                            rx='17.3838'
                            fill='#2357FF'
                        />
                        <path
                            d='M318.792 151.042H307.507L308.074 149.842L317.488 149.825C317.807 149.825 318.08 149.588 318.136 149.261L319.44 141.674C319.474 141.475 319.423 141.27 319.298 141.114C319.236 141.038 319.159 140.976 319.072 140.933C318.985 140.891 318.89 140.868 318.794 140.868L306.818 140.827L306.715 140.326C306.651 140.007 306.374 139.774 306.06 139.774H303.132C302.954 139.774 302.784 139.848 302.659 139.978C302.533 140.109 302.463 140.285 302.463 140.47C302.463 140.654 302.533 140.831 302.659 140.962C302.784 141.092 302.954 141.165 303.132 141.165H305.518L305.965 143.376L307.066 148.918L305.648 151.324C305.575 151.427 305.53 151.55 305.52 151.678C305.51 151.806 305.535 151.935 305.592 152.049C305.705 152.283 305.935 152.431 306.188 152.431H307.379C307.125 152.781 306.988 153.208 306.988 153.647C306.988 154.762 307.86 155.668 308.932 155.668C310.005 155.668 310.877 154.762 310.877 153.647C310.877 153.207 310.736 152.78 310.486 152.431H313.539C313.285 152.781 313.148 153.208 313.149 153.647C313.149 154.762 314.02 155.668 315.093 155.668C316.166 155.668 317.037 154.762 317.037 153.647C317.037 153.207 316.897 152.78 316.647 152.431H318.794C319.162 152.431 319.463 152.12 319.463 151.736C319.462 151.551 319.391 151.375 319.265 151.245C319.139 151.115 318.969 151.042 318.792 151.042ZM307.096 142.198L318.008 142.235L316.939 148.457L308.368 148.473L307.096 142.198ZM308.932 154.269C308.603 154.269 308.334 153.99 308.334 153.647C308.334 153.304 308.603 153.024 308.932 153.024C309.262 153.024 309.531 153.304 309.531 153.647C309.531 153.812 309.468 153.97 309.356 154.087C309.244 154.204 309.091 154.269 308.932 154.269ZM315.093 154.269C314.763 154.269 314.494 153.99 314.494 153.647C314.494 153.304 314.763 153.024 315.093 153.024C315.423 153.024 315.692 153.304 315.692 153.647C315.692 153.812 315.629 153.97 315.516 154.087C315.404 154.204 315.252 154.269 315.093 154.269Z'
                            fill='white'
                        />
                        <path
                            d='M340 141.761V152.357C340 152.708 340.14 153.045 340.391 153.294C340.641 153.542 340.98 153.682 341.333 153.682H352M350.667 145.735L347.333 149.046L344.667 146.397L342.667 148.384'
                            stroke='#1F2937'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M373.467 260.73H374.467C391.035 260.73 404.467 274.162 404.467 290.73V344.967C404.467 361.535 417.898 374.967 434.467 374.967H469.967'
                            stroke='#1F2937'
                        />
                        <rect
                            x='338.467'
                            y='242.85'
                            width='35'
                            height='34.7676'
                            rx='17.3838'
                            fill='white'
                        />
                        <g clipPath='url(#clip0_407_7641)'>
                            <path
                                d='M352.468 264.233H357.468M352.468 260.233H357.468C357.998 260.233 358.507 260.023 358.882 259.648C359.257 259.273 359.468 258.764 359.468 258.233C359.468 257.703 359.257 257.194 358.882 256.819C358.507 256.444 357.998 256.233 357.468 256.233H354.468V265.233M347.318 256.853C347.172 256.196 347.194 255.512 347.383 254.866C347.572 254.219 347.921 253.631 348.397 253.155C348.874 252.679 349.463 252.332 350.11 252.144C350.757 251.957 351.441 251.936 352.098 252.083C352.46 251.518 352.958 251.052 353.547 250.73C354.136 250.407 354.797 250.238 355.468 250.238C356.139 250.238 356.8 250.407 357.389 250.73C357.978 251.052 358.476 251.518 358.838 252.083C359.496 251.936 360.181 251.956 360.829 252.144C361.477 252.332 362.067 252.681 362.544 253.158C363.021 253.635 363.369 254.225 363.557 254.872C363.745 255.52 363.766 256.205 363.618 256.863C364.184 257.225 364.649 257.723 364.972 258.312C365.294 258.901 365.463 259.562 365.463 260.233C365.463 260.905 365.294 261.566 364.972 262.155C364.649 262.743 364.184 263.242 363.618 263.603C363.765 264.261 363.744 264.944 363.557 265.591C363.37 266.238 363.022 266.827 362.546 267.304C362.071 267.781 361.482 268.13 360.836 268.318C360.189 268.507 359.505 268.529 358.848 268.383C358.487 268.951 357.988 269.419 357.398 269.743C356.808 270.067 356.146 270.237 355.473 270.237C354.8 270.237 354.138 270.067 353.548 269.743C352.958 269.419 352.459 268.951 352.098 268.383C351.441 268.531 350.757 268.51 350.11 268.323C349.463 268.135 348.874 267.787 348.397 267.312C347.921 266.836 347.572 266.248 347.383 265.601C347.194 264.955 347.172 264.271 347.318 263.613C346.748 263.253 346.278 262.754 345.953 262.163C345.628 261.572 345.457 260.908 345.457 260.233C345.457 259.559 345.628 258.895 345.953 258.304C346.278 257.713 346.748 257.214 347.318 256.853Z'
                                stroke='#1F2937'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </g>
                        <rect
                            x='839.471'
                            y='337.716'
                            width='49'
                            height='48.6746'
                            rx='24.3373'
                            fill='#2357FF'
                        />
                        <g clipPath='url(#clip1_407_7641)'>
                            <path
                                d='M874.745 365.599H869.38C868.753 365.599 868.152 365.848 867.708 366.291C867.265 366.734 867.016 367.336 867.016 367.962V373.328M857.561 351.819V353.781C857.561 354.721 857.935 355.623 858.6 356.288C859.265 356.952 860.167 357.326 861.107 357.326C861.734 357.326 862.335 357.575 862.778 358.018C863.222 358.462 863.471 359.063 863.471 359.69C863.471 360.99 864.534 362.053 865.834 362.053C866.461 362.053 867.062 361.804 867.506 361.361C867.949 360.918 868.198 360.317 868.198 359.69C868.198 358.39 869.261 357.326 870.561 357.326H874.308M862.289 373.812V369.144C862.289 368.517 862.04 367.916 861.596 367.473C861.153 367.03 860.552 366.781 859.925 366.781C859.298 366.781 858.697 366.532 858.254 366.088C857.81 365.645 857.561 365.044 857.561 364.417V363.235C857.561 362.608 857.312 362.007 856.869 361.564C856.426 361.12 855.825 360.871 855.198 360.871H851.711M875.289 362.053C875.289 368.58 869.998 373.871 863.471 373.871C856.944 373.871 851.652 368.58 851.652 362.053C851.652 355.526 856.944 350.235 863.471 350.235C869.998 350.235 875.289 355.526 875.289 362.053Z'
                                stroke='white'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </g>
                        <path
                            d='M730.471 477.78H764.971C781.539 477.78 794.971 464.348 794.971 447.78V392.55C794.971 375.981 808.402 362.55 824.971 362.55H839.471'
                            stroke='#1F2937'
                        />
                        <path
                            d='M1043.47 218.215C1043.47 201.647 1030.04 188.215 1013.47 188.215H887.971C880.239 188.215 873.971 194.483 873.971 202.215V207.741C873.971 214.369 868.598 219.741 861.971 219.741H853.471C845.739 219.741 839.471 226.009 839.471 233.741V291.325C839.471 307.894 852.902 321.325 869.471 321.325H1013.47C1030.04 321.325 1043.47 307.894 1043.47 291.325V218.215Z'
                            fill='#FDA2F6'
                        />

                        <path
                            d='M730.471 431.092H815.849C826.409 431.092 834.971 422.531 834.971 411.97V411.97C834.971 401.409 843.532 392.848 854.093 392.848H917.471C934.039 392.848 947.471 379.416 947.471 362.848V321.326'
                            stroke='#1F2937'
                        />
                        <rect
                            x='998.916'
                            y='211.559'
                            width='73'
                            height='38.741'
                            rx='19.3705'
                            fill='#2357FF'
                        />
                        <rect
                            x='1000.92'
                            y='213.546'
                            width='35'
                            height='34.7676'
                            rx='17.3838'
                            fill='#FDA2F6'
                        />
                        <path
                            d='M1018.02 235.73C1017.91 236.111 1017.73 236.467 1017.49 236.779C1017.25 237.09 1016.94 237.351 1016.6 237.546C1016.26 237.741 1015.88 237.866 1015.49 237.915C1015.09 237.964 1014.7 237.935 1014.32 237.83C1013.94 237.725 1013.58 237.546 1013.27 237.303C1012.96 237.06 1012.69 236.758 1012.5 236.415C1012.3 236.071 1012.18 235.692 1012.13 235.3C1012.08 234.908 1012.11 234.511 1012.22 234.13M1009.42 229.93L1027.42 224.93V236.93L1009.42 232.93V229.93Z'
                            stroke='#1F2937'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M1049.92 238.009H1055.92M1049.92 234.009V231.009H1045.92L1052.92 224.009L1059.92 231.009H1055.92V234.009H1049.92Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M470.465 451.456L385.39 451.456C368.822 451.456 355.39 464.887 355.39 481.456V508.375C355.39 524.943 341.959 538.375 325.39 538.375H288.354'
                            stroke='#1F2937'
                        />
                        <rect
                            x='97.3535'
                            y='514.037'
                            width='191'
                            height='48.6746'
                            rx='24.3373'
                            fill='#FDA2F6'
                        />

                        <SectionMainTitle x='120' y='98' fill='white'>
                            <tspan x='180' dy='24px'>
                                Конверсия
                            </tspan>
                            <tspan x='129' dy='24px'>
                                в первый заказ
                            </tspan>
                            <tspan x='129' dy='24px'>
                                выше в 3-5 раз
                            </tspan>
                        </SectionMainTitle>
                        <SectionMainDescription x='108' y='180' fill='white'>
                            <tspan x='150' dy='19px'>
                                по сравнению с сайтом
                            </tspan>
                            <tspan x='108' dy='19px'>
                                или мобильным приложением
                            </tspan>
                        </SectionMainDescription>

                        <SectionAnotherTitle x='887' y='242' fill='#1f2937'>
                            {StaticText.intro.options[1].title}
                        </SectionAnotherTitle>
                        <SectionAnotherDescription
                            x='902'
                            y='256'
                            fill='#1f2937'
                        >
                            <tspan x='902' dy='22px'>
                                Коммуникация
                            </tspan>
                            <tspan x='902' dy='22px'>
                                с посетителями
                            </tspan>
                        </SectionAnotherDescription>

                        <SectionAnotherDescription
                            x='120'
                            y='545'
                            fill='#1f2937'
                        >
                            {StaticText.intro.note}
                        </SectionAnotherDescription>
                        <defs>
                            <pattern
                                id='pattern0_407_7641'
                                patternContentUnits='objectBoundingBox'
                                width='1'
                                height='1'
                            >
                                <use
                                    xlinkHref='#image0_407_7641'
                                    transform='matrix(0.00119101 0 0 0.000833287 -0.0911176 -0.0431292)'
                                />
                            </pattern>
                            <pattern
                                id='pattern1_407_7641'
                                patternContentUnits='objectBoundingBox'
                                width='1'
                                height='1'
                            >
                                <use
                                    xlinkHref='#image1_407_7641'
                                    transform='matrix(0.00128205 0 0 0.0007 0 -0.0931575)'
                                />
                            </pattern>
                            <clipPath id='clip0_407_7641'>
                                <rect
                                    width='22'
                                    height='22'
                                    fill='white'
                                    transform='translate(344.467 249.234)'
                                />
                            </clipPath>
                            <clipPath id='clip1_407_7641'>
                                <rect
                                    width='26'
                                    height='26'
                                    fill='white'
                                    transform='translate(850.471 349.053)'
                                />
                            </clipPath>

                            <image
                                id='image1_407_7641'
                                width='780'
                                height='1690'
                                xlinkHref={ShopExample}
                            />
                        </defs>
                    </svg>
                </StyledBackground>
            </StyledWrapper>
        </Container>
    );
};

const StyledWrapper = styled.div`
    overflow: hidden;

    @media (max-width: 920px) {
        margin: 0 -20px;
    }
`;

const StyledBackground = styled.div<{ $background: string }>`
    background-image: ${props => `url(${props.$background})`};
    background-position: -18px 0;
    background-repeat: no-repeat;
    margin: -50px 0 -53px;

    @media (max-width: 1200px) {
        background-size: 100% 100%;
        //margin: 0 -50px;
        margin: 0 -20px -31px;
    }

    @media (max-width: 576px) {
        margin: 0px -50px -31px -31px;
        padding-bottom: 12px;
    }
`;

const SectionMainTitle = styled.text`
    text-align: right;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const SectionMainDescription = styled.text`
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 136%;
`;

const SectionAnotherTitle = styled.text`
    color: #1f2937;

    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const SectionAnotherDescription = styled.text`
    color: #1f2937;

    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 136%; /* 21.76px */
`;

const StyledSectionSecond = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #6257d2;
    border-radius: 40px;
    padding: 64px;
    margin-bottom: 64px;

    @media (max-width: 1400px) {
        padding: 64px;
        margin-bottom: 64px;
    }

    @media (max-width: 992px) {
        padding: 40px;
        margin-bottom: 32px;
    }

    @media (max-width: 768px) {
        padding: 24px;
    }

    @media (max-width: 576px) {
        width: auto;
        flex-direction: column;
        gap: 24px;
        padding: 16px;
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0;
    }
`;
