import React from 'react';

export const Luding = props => {
    const { color, className, style } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='71'
            height='53'
            viewBox='0 0 71 53'
            fill='none'
            className={className}
            style={style}
        >
            <g clipPath='url(#clip0_460_1351)'>
                <path
                    d='M64.1568 1.15316C63.8374 1.19568 63.3614 1.305 63.1024 1.39611C61.3852 1.97311 60.3427 3.12102 59.8367 4.97953L59.668 5.5869V26.2372L59.8306 26.8202C60.168 28.0593 60.4753 28.6302 61.2044 29.3712C61.7949 29.9725 62.3733 30.3065 63.1927 30.5312C63.9037 30.7195 65.1388 30.7256 65.7353 30.5374C66.6271 30.2579 67.2899 29.7295 67.6815 29.0006C67.7961 28.7821 67.8924 28.6485 67.8924 28.697C67.8924 28.7455 67.9708 28.9521 68.0671 29.1525C68.26 29.5655 68.6335 29.9421 69.0432 30.1425C69.3746 30.3065 70.0435 30.4583 70.4411 30.4583H70.7243V15.5173H65.3619V19.7081H66.3318L66.2957 26.4194L66.1571 26.6684C65.7716 27.3426 64.8196 27.3243 64.4761 26.632C64.3978 26.4801 64.3073 26.2068 64.2773 26.0185C64.2351 25.7939 64.223 22.2954 64.2291 15.663C64.2531 4.66371 64.2231 5.41684 64.6207 4.94917C65.1269 4.35395 65.9402 4.56045 66.2415 5.36218C66.3439 5.62334 66.356 5.94525 66.3741 9.27358L66.3921 12.9056H70.7243V9.68659C70.7243 5.72051 70.664 5.04635 70.1941 3.92881C69.6277 2.58654 68.5672 1.66942 67.1393 1.28678C66.3198 1.06206 65.175 1.01347 64.1568 1.15316Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M2.09766 30.3976H10.5932V26.5713H6.67684V1.36575H2.09766V30.3976Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M11.9785 13.4644C11.9785 23.6924 11.9906 25.6603 12.0689 26.146C12.4244 28.533 13.8283 30.0878 16.0455 30.5495C16.7385 30.6891 17.8109 30.7317 18.5219 30.6406C21.155 30.3065 22.7215 28.7577 23.0951 26.1158C23.1674 25.6056 23.1855 23.5709 23.1855 13.434V1.36575H18.6063L18.5942 13.7377C18.5761 25.6056 18.5702 26.1279 18.4677 26.4194C18.2508 26.9964 17.8471 27.2575 17.359 27.1239C17.0517 27.039 16.8409 26.796 16.6962 26.3709C16.5938 26.0732 16.5878 25.3505 16.5697 13.7073L16.5578 1.36575H11.9785V13.4644Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M25.4141 30.3976H28.5772C32.0479 30.3976 32.6263 30.3612 33.4578 30.0879C35.187 29.523 36.1932 28.363 36.639 26.4194C36.7656 25.8788 36.7656 25.7331 36.7898 16.489C36.8017 10.4458 36.7837 6.82593 36.7475 6.31575C36.6692 5.33182 36.5125 4.71232 36.163 3.98955C35.4341 2.48937 34.1446 1.64514 32.2647 1.43256C31.9274 1.39005 30.3728 1.36575 28.5472 1.36575H25.4141V30.3976ZM31.4995 4.96133C31.8068 5.12533 32.0056 5.44722 32.1021 5.97563C32.1864 6.42507 32.1864 25.3384 32.1021 25.7877C32.0177 26.2311 31.825 26.6077 31.6019 26.7656C31.4332 26.8871 31.3188 26.9053 30.7343 26.9296L30.0534 26.9477V4.82772H30.6439C31.138 4.82772 31.2766 4.85201 31.4995 4.96133Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M38.9707 30.4583H43.6703V1.36575H38.9707V30.4583Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M45.9609 30.3976H49.9375V21.8946C49.9375 16.2643 49.9556 13.3915 49.9977 13.3915C50.034 13.3915 50.058 13.4219 50.058 13.4522C50.058 13.513 53.2153 29.9785 53.2816 30.2579L53.3116 30.3976H57.4088V1.36575H53.4321V8.65409C53.4321 12.9907 53.4081 15.9424 53.3779 15.9424C53.3479 15.9424 53.2936 15.7603 53.2514 15.5294C53.2153 15.3048 52.6609 12.031 52.0283 8.2593L50.8715 1.39612L48.4192 1.3779L45.9609 1.36575V30.3976Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M8.87505 39.2104C8.38099 39.2955 7.83871 39.587 7.52539 39.9393C7.03736 40.4859 6.89274 41.1176 6.88672 42.7088C6.88672 43.8811 6.96505 44.4762 7.194 44.9622C7.44105 45.5028 7.60975 45.7032 8.67622 46.681C9.36912 47.3187 9.64628 47.6164 9.73064 47.8106C9.83306 48.0476 9.84511 48.1994 9.82703 49.4323C9.80896 50.7807 9.80896 50.7989 9.66436 50.9508C9.40528 51.2301 9.04979 51.1026 8.9353 50.6957C8.90518 50.568 8.87505 49.8272 8.87505 48.9951V47.5253H6.88672V48.8432C6.88672 49.5721 6.91685 50.3495 6.94697 50.5742C7.15785 52.0561 8.01344 52.7424 9.5559 52.6756C10.0921 52.6514 10.2368 52.6209 10.5742 52.4631C11.2189 52.1594 11.6226 51.6431 11.7913 50.9265C11.8997 50.4588 11.9238 48.0536 11.8274 47.5191C11.6828 46.7417 11.3936 46.3591 9.83306 44.8771C9.51372 44.5795 9.18836 44.209 9.11003 44.0633C8.97146 43.802 8.96543 43.7414 8.96543 42.472C8.96543 41.2269 8.97146 41.148 9.09197 40.984C9.23054 40.7957 9.35105 40.7531 9.57398 40.8078C9.86319 40.8808 9.89934 41.1176 9.89934 42.7878V44.3062H11.8997L11.8756 42.7088C11.8515 41.2816 11.8334 41.075 11.7129 40.7106C11.496 40.0304 11.0441 39.5445 10.4416 39.3319C10.0319 39.1861 9.3149 39.1315 8.87505 39.2104Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M28.3548 39.2044C27.1738 39.417 26.505 40.1457 26.3484 41.3908C26.276 41.9982 26.27 44.7556 26.3423 45.3752C26.4026 45.8732 26.6136 46.3834 26.8665 46.6385C27.4993 47.2762 28.6802 47.1608 29.1502 46.4198C29.2526 46.2619 29.3549 46.1343 29.3791 46.1343C29.4031 46.1283 29.4212 47.1487 29.4092 48.3938C29.3912 50.4527 29.3791 50.6714 29.2826 50.8231C29.0537 51.1754 28.656 51.1876 28.4753 50.8475C28.3789 50.6774 28.3668 50.4467 28.3487 49.1469L28.3247 47.6466L27.3546 47.6589L26.3786 47.677L26.3605 48.8614C26.3303 50.7929 26.4749 51.4245 27.0654 52.0258C27.5775 52.536 27.915 52.6574 28.9091 52.6574C29.596 52.6574 29.7346 52.6392 30.0538 52.5116C30.548 52.3112 31.0963 51.7586 31.295 51.2605L31.4397 50.8961V40.9961L31.3011 40.6499C30.9276 39.7267 30.2286 39.2591 29.0899 39.18C28.861 39.1619 28.5295 39.174 28.3548 39.2044ZM29.1983 40.8929C29.3912 41.1054 29.4333 41.5791 29.4092 43.4499C29.3912 45.1869 29.3851 45.2779 29.2646 45.442C29.0839 45.6849 28.7344 45.6849 28.5537 45.442C28.4331 45.2779 28.4271 45.1869 28.409 43.4984C28.385 41.5244 28.409 41.1661 28.5837 40.9414C28.7344 40.7531 29.0476 40.7289 29.1983 40.8929Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M34.5133 39.2044C33.3444 39.3927 32.6455 40.1519 32.4948 41.3908C32.4225 41.9982 32.4166 44.7617 32.4888 45.3751C32.549 45.8732 32.76 46.3834 33.0129 46.6385C33.6457 47.2762 34.8266 47.1608 35.2966 46.4198C35.399 46.2619 35.5014 46.1343 35.5255 46.1343C35.5497 46.1283 35.5677 47.1487 35.5556 48.3938C35.5376 50.4527 35.5255 50.6714 35.4292 50.8231C35.2001 51.1754 34.8025 51.1876 34.6217 50.8475C34.5254 50.6774 34.5133 50.4467 34.4952 49.1469L34.4711 47.6466L33.501 47.6589L32.525 47.677L32.5069 48.8614C32.4768 50.7929 32.6214 51.4245 33.2118 52.0258C33.7239 52.536 34.0614 52.6573 35.0556 52.6573C35.7424 52.6573 35.881 52.6392 36.2004 52.5116C36.6944 52.3112 37.2427 51.7586 37.4416 51.2605L37.5861 50.8961V40.9961L37.4475 40.6499C37.074 39.7267 36.3751 39.2591 35.2363 39.18C35.0074 39.1679 34.682 39.174 34.5133 39.2044ZM35.3448 40.8929C35.5376 41.1054 35.5798 41.5791 35.5556 43.4499C35.5376 45.1869 35.5316 45.2779 35.4111 45.442C35.2303 45.6849 34.8808 45.6849 34.7001 45.442C34.5796 45.2779 34.5735 45.1869 34.5554 43.4984C34.5314 41.5244 34.5554 41.1661 34.7301 40.9414C34.8808 40.7531 35.1942 40.7289 35.3448 40.8929Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M40.4964 39.2104C39.7312 39.3198 39.0744 39.8359 38.8213 40.5223C38.6527 40.9718 38.5743 41.8829 38.5924 43.0611L38.6105 44.0328L39.5926 44.0511L40.5687 44.0633V42.6603C40.5687 41.4941 40.5867 41.2208 40.6651 41.0508C40.7735 40.8261 40.9543 40.7471 41.2074 40.8078C41.5026 40.8867 41.5327 41.0993 41.5327 42.9397V44.622L41.3579 44.7981C41.1893 44.9682 41.1592 44.9743 40.6048 44.9743H40.0204L40.0565 46.766L40.6772 46.7964C41.599 46.8389 41.5387 46.6932 41.5207 48.9586L41.5026 50.7929L41.3519 50.9567C41.1592 51.1633 40.9001 51.145 40.7253 50.9082C40.6048 50.7442 40.5988 50.6471 40.5808 49.1893L40.5627 47.6466L39.5865 47.6589L38.6105 47.677L38.6166 49.1347C38.6166 50.7016 38.6587 51.0297 38.9239 51.5703C39.1166 51.9711 39.442 52.2809 39.8999 52.4995C40.2132 52.6452 40.2975 52.6573 41.0508 52.6573C41.7497 52.6513 41.9123 52.6331 42.2076 52.5177C42.9246 52.2262 43.4427 51.5399 43.5813 50.6774C43.6235 50.4284 43.6416 49.6874 43.6295 48.7035C43.6054 47.3855 43.5874 47.0819 43.4969 46.8449C43.3704 46.4987 42.9909 46.0494 42.7317 45.946L42.5449 45.8671L42.7136 45.776C43.1354 45.5634 43.4669 45.0958 43.5753 44.5613C43.6777 44.0633 43.6596 41.5063 43.5511 41.0386C43.3041 39.9878 42.6233 39.3623 41.5629 39.2044C41.0869 39.1376 40.9784 39.1376 40.4964 39.2104Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M2.06641 52.5662H6.16356V50.8656H4.17525V46.7355H5.98884L5.95268 45.0047L4.17525 44.9681V41.0265H6.16356V39.3259H2.06641V52.5662Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M12.4297 41.0265H13.8155V52.5662H15.9243V41.0265H17.3102V39.3259H12.4297V41.0265Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M23.8008 39.6295C23.602 40.1882 23.2887 40.4252 22.6198 40.5163L22.3125 40.5588V42.1197H23.1561V52.5662H25.2649V39.3259H23.9092L23.8008 39.6295Z'
                    fill={color ? color : '#4A4DD0'}
                />
                <path
                    d='M17.3711 52.6271H19.4799V50.2584H17.3711V52.6271Z'
                    fill={color ? color : '#4A4DD0'}
                />
            </g>
            <defs>
                <clipPath id='clip0_460_1351'>
                    <rect
                        width='70'
                        height='52'
                        fill='white'
                        transform='translate(0.5 0.708008)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
