import React, { useEffect, useState, useMemo } from 'react';
import { TreeSelect } from 'antd';
import { useParams } from 'react-router-dom';

import { useLazyReadProductCategoryQuery } from '../../services/categories';

type TreeCategoryFilterProps = {
    updateFilters: (filters) => void;
    width?: number;
    isDisabled?: boolean;
};
export const TreeCategoryFilter: React.FC<TreeCategoryFilterProps> = props => {
    const { isDisabled = false, updateFilters } = props;

    const { id } = useParams();

    const [active, setActive] = useState<number | string>('Все категории');

    const [getCategories, { data }] = useLazyReadProductCategoryQuery();

    useEffect(() => {
        if (id) {
            getCategories({ shop_id: +id });
        }
    }, [id]);

    const onChange = (newValueId: number | 'null') => {
        updateFilters({
            category_id: newValueId === 'null' ? null : newValueId,
        });
        setActive(newValueId);
    };

    const dropdownItems = useMemo(() => {
        return data?.items?.map(category => {
            if (category.id === null) {
                return {
                    ...category,
                    id: 'null',
                };
            }
            return category;
        });
    }, [data]);

    return (
        <TreeSelect
            style={{ width: props?.width || 300 }}
            treeData={dropdownItems}
            fieldNames={{
                label: 'name',
                value: 'id',
                children: 'childCategoryList',
            }}
            onChange={onChange}
            defaultValue={'null'}
            value={active}
            disabled={isDisabled}
        />
    );
};
