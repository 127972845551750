import React, { memo } from 'react';
import { Button, Flex, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { TreeCategoryFilter } from '../Categories/TreeCategoryFilter';
import { useManageProducts } from '../../hooks/useManageProducts';

type ProductTabs = {
    readonly updateFilters: (filters) => void;
};
export const ProductTabs = memo((props: ProductTabs) => {
    const { updateFilters } = props;

    const { id, type } = useParams();
    const { manageProduct } = useManageProducts();

    const renderButton = (type: string) => {
        switch (type) {
            case 'product-order':
                return 'Добавить заявку';
            case 'links':
                return 'Добавить витрину';
            default:
                return 'Добавить товар';
        }
    };

    return (
        <Flex justify={'space-between'} wrap={'wrap'} gap={20}>
            <Flex vertical={false} gap={12}>
                <Button
                    type={'primary'}
                    onClick={() => {
                        manageProduct(type, +id);
                    }}
                >
                    <PlusOutlined /> {renderButton(type)}
                </Button>

                <div>
                    <Input
                        allowClear
                        onChange={e => updateFilters({ title: e.target.value })}
                        placeholder={'Поиск товаров'}
                    />
                </div>
            </Flex>

            <TreeCategoryFilter updateFilters={updateFilters} />
        </Flex>
    );
});
