import React, { FC, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Flex } from 'antd';
import Slider from 'react-slick';

import StaticText from 'shared/layouts/LandingLayout/content.json';
import Museum from 'static/landing/Museum.png';
import Opera from 'static/landing/Opera.png';
import Pushkin from 'static/landing/Pushkin.png';
import MiniApp1 from 'static/landing/MiniApp1.png';
import MiniApp2 from 'static/landing/MiniApp2.png';
import MiniApp3 from 'static/landing/MiniApp3.png';
import MiniApp4 from 'static/landing/MiniApp4.png';
import EcomBackground from 'static/landing/EcomBackground.png';

import { SubTitle, Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { Arrow } from '../../../ui/Icons/Arrow';

export const EcomOpportunities: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();
    const { hash } = useLocation();

    const slider1Ref = useRef(null);
    const slider2Ref = useRef(null);

    useLayoutEffect(() => {
        if (refProp && hash === '#create-store') {
            setTimeout(() => {
                refProp.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                });
            }, 500);
        }
    }, [refProp]);

    const renderImage = name => {
        switch (name) {
            case 'MiniApp1':
                return <StyledImage src={MiniApp1} />;

            case 'MiniApp2':
                return <StyledImage src={MiniApp2} />;

            case 'MiniApp3':
                return <StyledImage src={MiniApp3} />;
            case 'MiniApp4':
                return <StyledImage src={MiniApp4} />;

            default:
                return <></>;
        }
    };

    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => {
            if (slider2Ref.current) {
                slider2Ref.current.slickGoTo(next);
            }
        },
    };

    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false,
        beforeChange: (current, next) => {
            if (slider1Ref.current) {
                slider1Ref.current.slickGoTo(next);
            }
        },
    };

    return (
        <Container
            maxWidth={1000}
            marginTop={102}
            mobileMarginTop={40}
            refProp={refProp}
        >
            <StyledWrapper>
                <SubTitle
                    id={'telegram-advantages'}
                    type='primary'
                    text={StaticText.ecomOpportunities.title}
                />
                <StyledSlidersGroup>
                    <StyledEcomBackground $background={EcomBackground} />
                    <StyledSliderMain>
                        <Slider ref={slider1Ref} {...settings1}>
                            {StaticText.ecomOpportunities.options.map(
                                (option, index) => (
                                    <StyledOption
                                        key={`${option.title}${index}`}
                                    >
                                        <StyledOptionTitle>
                                            {option.title}
                                        </StyledOptionTitle>
                                        <StyledOptionDescription>
                                            {option.description}
                                        </StyledOptionDescription>
                                        <StyledOptionList>
                                            {option.optionsList.map(
                                                (listItem, index) => (
                                                    <li key={index}>
                                                        {listItem}
                                                    </li>
                                                )
                                            )}
                                        </StyledOptionList>
                                    </StyledOption>
                                )
                            )}
                        </Slider>
                        <StyledArrowContainer>
                            <StyledArrow
                                onClick={() => slider1Ref.current.slickPrev()}
                            >
                                <Arrow rotate={180} />
                            </StyledArrow>
                            <StyledArrow
                                onClick={() => slider1Ref.current.slickNext()}
                            >
                                <Arrow />
                            </StyledArrow>
                        </StyledArrowContainer>
                    </StyledSliderMain>
                    <StyledSliderImages>
                        <Slider ref={slider2Ref} {...settings2}>
                            {StaticText.ecomOpportunities.options.map(
                                (option, index) => (
                                    <StyledOption
                                        key={`${option.image}${index}`}
                                    >
                                        {renderImage(option.image)}
                                    </StyledOption>
                                )
                            )}
                        </Slider>
                    </StyledSliderImages>
                </StyledSlidersGroup>
            </StyledWrapper>
        </Container>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 66px;
    justify-content: center;
    @media (max-width: 920px) {
        gap: 28px;
    }
`;

const StyledSlidersGroup = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: 920px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
`;

const StyledEcomBackground = styled.div<{ $background: string }>`
    width: 577px;
    height: 1067px;
    position: absolute;
    z-index: 0;
    top: calc(50% - 509px);
    right: calc(50% - 50vw - 20px);
    background-image: ${props => `url(${props.$background})`};
    background-position-x: -20%;
    background-position-y: center;
    background-repeat: no-repeat;

    @media (max-width: 920px) {
        top: calc(50% - 575px);
        background-size: 427px 801px;
        background-position: 176% -10%;
        right: -20px;
        z-index: -1;
        //display: none;
    }
`;

const StyledSliderMain = styled.div`
    display: block;
    position: relative;
    width: 491px;
    margin: 0 -8px;
    max-width: 100%;
    @media (max-width: 920px) {
        width: 720px;
    }
`;

const StyledSliderImages = styled.div`
    display: block;
    position: relative;
    width: 420px;
    @media (max-width: 920px) {
        width: 100%;
    }
`;

const StyledOption = styled.div`
    position: relative;
    padding: 0 8px;
`;

const StyledImage = styled.img`
    height: 460px;
    outline: none;
    @media (max-width: 920px) {
        height: 309px;
        margin: 0 auto 20px;
    }
`;

const StyledOptionTitle = styled.h2`
    color: #2357ff;

    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    margin: 0;

    @media (max-width: 920px) {
        font-size: 16px;
        line-height: 22px;
    }
`;

const StyledOptionDescription = styled.div`
    color: #1f2937;
    margin-top: 33px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 27.2px */

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
        margin-top: 12px;
    }
`;

const StyledOptionList = styled.ul`
    margin-top: 23px;
    padding-left: 36px;
    list-style: none;

    @media (max-width: 920px) {
        margin-top: 10px;
        padding-left: 16px;
    }

    li {
        color: #1f2937;
        position: relative;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        @media (max-width: 920px) {
            font-size: 14px;
            line-height: 27px;
        }

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background: rgba(35, 87, 255, 0.5);
            position: absolute;
            left: -36px;
            top: 10px;

            @media (max-width: 920px) {
                width: 8px;
                height: 8px;

                left: -16px;
                top: 9px;
            }
        }
    }
`;

const StyledArrowContainer = styled.div`
    display: flex;
    gap: 46px;
    margin-top: 21px;
    @media (max-width: 920px) {
        gap: 36px;
        margin-top: -2px;
        padding: 0 8px;
    }
`;

const StyledArrow = styled.div`
    cursor: pointer;
    width: 54px;
    height: 54px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);

    @media (max-width: 920px) {
        width: 40px;
        height: 40px;
    }
`;
