import React from 'react';

export const TsvetnoeRu = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='166'
            height='39'
            viewBox='0 0 166 39'
            fill='none'
        >
            <g clipPath='url(#clip0_460_1332)'>
                <path
                    d='M100.992 34.6798C106.809 34.6798 111.525 31.2591 111.525 27.0394C111.525 22.8197 106.809 19.3989 100.992 19.3989C95.1748 19.3989 90.459 22.8197 90.459 27.0394C90.459 31.2591 95.1748 34.6798 100.992 34.6798Z'
                    fill='#868CFF'
                />
                <path
                    d='M16.0435 30.0202H15.7019V16.1596H11.8303V30.0202H7.90166C6.81987 30.0202 5.96583 29.8469 5.33954 29.1539C4.71325 28.4608 4.37165 27.4213 4.37165 26.0931V16.2173H0.5V26.2085C0.5 28.5764 1.06937 30.3667 2.15116 31.6373C3.28987 32.9078 4.82712 33.2543 6.81988 33.2543H14.5632V36.8927H18.1501V32.157C18.0932 31.002 17.1822 30.0202 16.0435 30.0202Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M32.5551 29.5005C32.5551 30.7133 32.0996 31.5796 31.2456 32.2726C30.3915 32.9079 29.0251 33.2544 27.317 33.2544H20.4277V20.1446H27.0323C28.6265 20.1446 29.9361 20.4333 30.847 21.0109C31.758 21.5884 32.2134 22.4547 32.2134 23.4942C32.2134 24.1295 32.0427 24.7071 31.701 25.2268C31.3594 25.7466 30.9039 26.0931 30.2776 26.3819C31.8149 26.9016 32.5551 27.9412 32.5551 29.5005ZM23.8439 25.5155H26.6907C28.0002 25.5155 28.6834 25.0535 28.6834 24.0718C28.6834 23.1477 28.0002 22.6857 26.6907 22.6857H23.8439V25.5155ZM29.0251 29.2117C29.0251 28.692 28.8543 28.3455 28.5696 28.1144C28.228 27.8834 27.7156 27.7679 27.0323 27.7679H23.787V30.6555H26.9185C28.3418 30.6555 29.0251 30.1935 29.0251 29.2117Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M43.6008 30.078C44.1133 29.847 44.6257 29.5583 45.0812 29.0962L47.074 31.2909C45.8783 32.7346 44.0564 33.4277 41.722 33.4277C40.2417 33.4277 38.9891 33.1389 37.8503 32.5613C36.7116 31.9838 35.8576 31.1753 35.2313 30.1358C34.605 29.0962 34.3203 27.9411 34.3203 26.6707C34.3203 25.4001 34.605 24.245 35.2313 23.2055C35.8576 22.1659 36.6547 21.3574 37.7365 20.7799C38.7613 20.2024 39.9569 19.9136 41.2665 19.9136C42.5191 19.9136 43.6008 20.2024 44.6257 20.7221C45.6506 21.2419 46.4477 21.9927 47.017 23.0322C47.6433 24.014 47.9279 25.2268 47.9279 26.5551L38.3627 28.4609C38.6474 29.0962 39.046 29.616 39.6723 29.9048C40.2417 30.2512 40.9818 30.3668 41.8359 30.3668C42.4621 30.3668 43.0884 30.309 43.6008 30.078ZM38.9321 23.7253C38.3627 24.3605 38.0212 25.2268 38.0212 26.2663L44.341 24.9958C44.1702 24.3028 43.8286 23.783 43.2592 23.3787C42.6899 22.9745 42.0636 22.7434 41.2665 22.7434C40.2986 22.8012 39.5584 23.09 38.9321 23.7253Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M70.9302 21.3574C71.8981 22.3392 72.3536 23.783 72.3536 25.6888V33.1966H68.6528V26.2663C68.6528 25.2268 68.425 24.476 68.0265 23.9562C67.628 23.4365 67.0016 23.2054 66.2045 23.2054C65.3505 23.2054 64.6104 23.4942 64.0979 24.0718C63.5855 24.6493 63.3008 25.5155 63.3008 26.6706V33.2544H59.543V26.3241C59.543 24.245 58.7459 23.2054 57.0948 23.2054C56.2407 23.2054 55.5576 23.4942 55.0451 24.0718C54.5327 24.6493 54.2481 25.5155 54.2481 26.6706V33.2544H50.4902V20.1446H54.0772V21.6461C54.5327 21.0686 55.159 20.6644 55.8422 20.3756C56.5254 20.0869 57.3225 19.9136 58.1197 19.9136C59.0306 19.9136 59.8847 20.0869 60.6248 20.4911C61.365 20.8376 61.9344 21.4152 62.3898 22.1082C62.9023 21.4152 63.5855 20.8953 64.3826 20.5489C65.1797 20.2024 66.0907 19.9713 67.0586 19.9713C68.7097 19.9136 70.0192 20.4333 70.9302 21.3574Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M75.6562 20.1446H79.4141V25.2268H85.0508V20.1446H88.7515V33.2544H85.0508V28.3454H79.4141V33.2544H75.6562V20.1446Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M123.14 30.078C123.653 29.847 124.165 29.5583 124.621 29.0962L126.613 31.2909C125.418 32.7346 123.596 33.4277 121.262 33.4277C119.781 33.4277 118.529 33.1389 117.39 32.5613C116.251 31.9838 115.397 31.1753 114.771 30.1358C114.145 29.0962 113.859 27.9411 113.859 26.6707C113.859 25.4001 114.145 24.245 114.771 23.2055C115.397 22.1659 116.194 21.3574 117.276 20.7799C118.301 20.2024 119.497 19.9136 120.806 19.9136C122.058 19.9136 123.14 20.2024 124.165 20.7221C125.19 21.2419 125.987 21.9927 126.557 23.0322C127.183 24.014 127.468 25.2268 127.468 26.5551L117.902 28.4609C118.187 29.0962 118.585 29.616 119.212 29.9048C119.781 30.2512 120.521 30.3668 121.376 30.3668C122.058 30.3668 122.628 30.309 123.14 30.078ZM118.529 23.7253C117.959 24.3605 117.618 25.2268 117.618 26.2663L123.937 24.9958C123.766 24.3028 123.425 23.783 122.855 23.3787C122.287 22.9745 121.66 22.7434 120.863 22.7434C119.895 22.8012 119.098 23.09 118.529 23.7253Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M129.801 32.7345C129.345 32.2725 129.117 31.6949 129.117 31.0019C129.117 30.3089 129.345 29.7313 129.801 29.3271C130.256 28.9228 130.826 28.6918 131.452 28.6918C132.078 28.6918 132.648 28.9228 133.103 29.3271C133.559 29.7313 133.786 30.3089 133.786 31.0019C133.786 31.6949 133.559 32.2725 133.103 32.7345C132.648 33.1965 132.078 33.4275 131.452 33.4275C130.826 33.4275 130.256 33.1965 129.801 32.7345Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M146.655 20.7798C147.68 21.3573 148.477 22.1082 149.046 23.1477C149.616 24.1872 149.9 25.3423 149.9 26.7283C149.9 28.0567 149.616 29.2117 149.103 30.2512C148.591 31.2908 147.85 32.0416 146.883 32.6191C145.915 33.1966 144.833 33.4854 143.58 33.4854C141.929 33.4854 140.62 32.9656 139.709 31.9261V38.0478H135.951V26.7861C135.951 25.4578 136.236 24.245 136.862 23.2054C137.432 22.1659 138.286 21.3573 139.367 20.8376C140.449 20.2601 141.701 19.9713 143.124 19.9713C144.435 19.9136 145.63 20.2023 146.655 20.7798ZM145.288 29.3272C145.915 28.6342 146.199 27.7679 146.199 26.7283C146.199 25.6311 145.915 24.7647 145.288 24.0718C144.662 23.3787 143.865 23.0322 142.897 23.0322C141.929 23.0322 141.132 23.3787 140.506 24.014C139.879 24.707 139.595 25.5733 139.595 26.6706C139.595 27.7679 139.879 28.6342 140.506 29.3272C141.075 30.0202 141.872 30.309 142.897 30.309C143.865 30.309 144.662 29.9624 145.288 29.3272Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M165.501 20.1446V31.0598C165.501 33.4854 164.874 35.2758 163.679 36.4308C162.483 37.5859 160.718 38.1634 158.441 38.1634C157.245 38.1634 156.049 37.9901 154.967 37.7013C153.886 37.4126 152.975 36.9506 152.234 36.3731L153.715 33.6586C154.284 34.1207 154.911 34.4672 155.708 34.7559C156.505 35.0447 157.301 35.1602 158.099 35.1602C159.351 35.1602 160.262 34.8714 160.832 34.2939C161.401 33.7164 161.686 32.7924 161.686 31.6373V31.1753C161.23 31.6373 160.661 32.0416 159.978 32.2726C159.294 32.5614 158.612 32.6769 157.871 32.6769C156.163 32.6769 154.797 32.1571 153.829 31.1753C152.861 30.1935 152.348 28.692 152.348 26.7284V20.1446H156.106V26.1508C156.106 28.2877 156.96 29.3272 158.725 29.3272C159.637 29.3272 160.319 29.0384 160.889 28.4609C161.458 27.8834 161.686 26.9594 161.686 25.8043V20.1446H165.501Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M107.116 33.1641C108.451 29.377 110.448 22.2233 110.869 20.7112C109.558 20.685 108.466 20.2492 107.314 19.6993C106.738 21.8506 104.961 28.6524 103.723 34.1702C104.857 33.9696 105.932 33.6549 107.116 33.1641Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M107.73 18.579C108.47 19.503 110.179 20.0227 111.26 19.6762C111.602 18.6367 112.855 12.9192 112.855 11.2444C112.342 11.1867 111.488 10.9556 111.033 10.6669C110.122 12.0529 108.015 17.5972 107.73 18.579Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M111.831 9.91601C110.179 8.93424 111.261 6.39315 112.172 5.00709C113.254 3.39002 116.157 0.964416 118.15 1.07992C117.638 1.48419 116.841 2.35048 116.385 3.39002C115.816 4.66057 115.645 6.62416 115.133 7.95246C114.791 8.93424 113.481 10.8979 111.831 9.91601Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M99.3481 34.508C99.6614 27.7398 99.8751 19.0329 99.8751 19.0329C99.9078 17.8732 98.9657 19.1217 97.8829 19.0864C96.8572 19.0495 96.0915 17.8469 96.1158 19.0051C96.1158 19.0051 96.3431 27.1106 96.5401 33.8007C97.067 34.0845 98.2638 34.4084 99.3481 34.508Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M95.5469 7.99306C96.016 11.9099 95.3712 16.2689 97.3064 16.9639C97.8934 17.1533 99.1837 16.9639 99.5358 16.4585C100.708 14.7527 101.237 12.5417 100.591 8.75117C98.7146 7.92988 97.6584 7.80354 95.5469 7.99306Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M99.7285 34.516C100.416 29.8202 102.797 11.6629 102.797 11.6629L105.188 6.75264C105.298 6.57669 105.584 6.62756 105.645 6.7994L106.569 12.2074C106.569 12.2074 103.689 30.3681 103.15 34.136C102.77 34.516 100.813 34.6055 99.7285 34.516Z'
                    fill='#4A4DD0'
                />
                <path
                    d='M105.966 9.74281C105.681 9.85833 105.51 9.97385 105.226 9.91609C104.998 9.85833 104.827 9.7428 104.599 9.51184L103.574 11.5331C103.973 11.8219 104.371 12.0529 104.827 12.1107C105.282 12.1684 105.738 12.1107 106.194 11.9375L105.966 9.74281Z'
                    fill='#4A4DD0'
                />
            </g>
            <defs>
                <clipPath id='clip0_460_1332'>
                    <rect
                        width='165'
                        height='38'
                        fill='white'
                        transform='translate(0.5 0.708008)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
