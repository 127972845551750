import React from 'react';
import { Button, Flex, Modal, ModalProps, Row } from 'antd';
import styled from 'styled-components';

import { CrossInCyrcleIcon } from '../Icons/CrossInCyrcleIcon';

type GenericFlexModalProps = React.PropsWithChildren<ModalProps> & {
    readonly onConfirm?: () => void | any;
    readonly activator: React.ReactElement;
    readonly onCancel?: () => void;
    readonly isOpen?: boolean;
};

export const GenericFlexModal: React.FC<GenericFlexModalProps> = props => {
    const { onCancel, width, footer, title, centered = false } = props;

    return (
        <StyledModal
            destroyOnClose
            width={width || 660}
            onCancel={onCancel}
            footer={footer || null}
            open={props.isOpen}
            title={<StyledTitle>{title}</StyledTitle>}
            centered={props?.centered}
            styles={modalStyles}
            closeIcon={<CrossInCyrcleIcon />}
        >
            {props.children}
            {footer !== null && (
                <StyledActions>
                    <Flex gap={40} justify={'center'}>
                        <StyledPrimaryButton
                            {...props.okButtonProps}
                            block
                            onClick={() => {
                                props.onConfirm && props.onConfirm();
                            }}
                            type='primary'
                        >
                            {props.okText || 'Ок'}
                        </StyledPrimaryButton>

                        <StyledSecondaryButton
                            onClick={() => {
                                props.onCancel && props.onCancel();
                            }}
                            block
                        >
                            {props.cancelText || 'Отмена'}
                        </StyledSecondaryButton>
                    </Flex>
                </StyledActions>
            )}
        </StyledModal>
    );
};

const modalStyles = {
    header: {
        textAlign: 'center',
    },
    body: {},
    mask: {
        backdropFilter: 'blur(5px)',
    },
    footer: {},
    content: { padding: '40px 60px 30px 60px', borderRadius: 30 },
};

const StyledTitle = styled.h4`
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    line-height: 36px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: '#2357FF';
    margin-bottom: 33px;

    @media (max-width: 920px) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
    }
`;

const StyledPrimaryButton = styled(Button)`
    background-color: #2357ff;
    padding: 18px;
    width: auto !important;
    height: auto;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @media (max-width: 920px) {
        width: 100%;
    }
`;

const StyledSecondaryButton = styled(Button)`
    padding: 18px;
    width: auto !important;
    height: auto;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @media (max-width: 920px) {
        width: 100%;
    }
`;

const StyledModal = styled(Modal)`
    @media (max-width: 920px) {
        .ant-modal-content {
            padding: 28px 20px !important;
            width: auto;
            margin: 0 12px;
        }

        .ant-modal-header {
            margin-bottom: 16px;
        }
    }
`;

const StyledActions = styled.div`
    margin-top: 32px;
`;
