import React from 'react';
import styled from 'styled-components';

import { TitleProps } from '../types/landing.interface';

export const Title: React.FC<TitleProps> = props => {
    return <StyledTitle id={props.id}>{props.text}</StyledTitle>;
};

export const SubTitle: React.FC<TitleProps> = props => {
    return <StyledSubTitle $type={props.type}>{props.text}</StyledSubTitle>;
};

const StyledTitle = styled.h1`
    margin-top: 50px;
    font-family: 'Inter', sans-serif;
    font-size: 56px;
    letter-spacing: 1px;
    line-height: 67px;
    font-style: normal;
    font-weight: 700;
    color: #1f2937;
    text-align: center;
    @media (max-width: 920px) {
        font-size: 24px;
        line-height: 33px;
        margin: 28px -8px 19px;
    }
`;

const StyledSubTitle = styled.h2<{ $type: string }>`
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    line-height: 136%;
    font-style: normal;
    font-weight: 700;
    //margin: 0;
    color: ${props => (props.$type === 'primary' ? '#1F2937' : '#2357FF')};
    @media (max-width: 920px) {
        font-size: 18px;
        line-height: 24px;
    }
`;
