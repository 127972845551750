import { useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
    useDeleteProductMutation,
    useUpdateProductStatusMutation,
} from '../services/products';
import { ProductStatus } from '../interfaces/products.interface';
import getEnvConfig from '../../../utils/config/env';

export const useManageProducts = () => {
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const [
        deleteProduct,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeleteProductMutation();

    const [updateProductStatus, { isSuccess: isSuccessUpdateStatus }] =
        useUpdateProductStatusMutation();

    useEffect(() => {
        if (isSuccessDelete) {
            notification.success({
                message: 'Товар удален',
            });
        }
    }, [isSuccessDelete]);

    useEffect(() => {
        if (isSuccessUpdateStatus) {
            notification.success({
                message: 'Статус товара изменен',
            });
        }
    }, [isSuccessUpdateStatus]);

    const changeProductStatus = async (id: number, status: number) => {
        if (status === ProductStatus.archived) {
            await updateProductStatus({
                id: id,
                status: ProductStatus.active,
            });
        } else
            updateProductStatus({
                id: id,
                status: ProductStatus.archived,
            });
    };

    const manageProduct = (
        type: string,
        shopId: number,
        productId?: number
    ) => {
        const isEdit = typeof productId === 'number';

        return navigate(
            `${getEnvConfig().PUBLIC_PATH}/shops/${+shopId}/${
                isEdit ? `${type}-edit/${productId}` : `${type}-create`
            }`
        );
    };

    return {
        changeProductStatus,
        deleteProduct,
        isLoadingDelete,
        manageProduct,
    };
};
