import React from 'react';

export const Talk = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='81'
            height='81'
            viewBox='0 0 81 81'
            fill='none'
        >
            <path
                d='M60.472 30.7251H67.1387C68.9068 30.7251 70.6025 31.4275 71.8527 32.6777C73.103 33.928 73.8053 35.6237 73.8053 37.3918V74.0585L60.472 60.7251H40.472C38.7039 60.7251 37.0082 60.0227 35.758 58.7725C34.5077 57.5223 33.8053 55.8266 33.8053 54.0585V50.7251M47.1387 30.7251C47.1387 32.4932 46.4363 34.1889 45.1861 35.4392C43.9358 36.6894 42.2401 37.3918 40.472 37.3918H20.472L7.13867 50.7251V14.0585C7.13867 12.2903 7.84105 10.5946 9.09129 9.34441C10.3415 8.09416 12.0372 7.39178 13.8053 7.39178H40.472C42.2401 7.39178 43.9358 8.09416 45.1861 9.34441C46.4363 10.5946 47.1387 12.2903 47.1387 14.0585V30.7251Z'
                stroke={color ? color : '#FDA2F6'}
                strokeWidth='5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
