import React, { Fragment, useEffect, useState } from 'react';
import { App, Button, Modal } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useCreateSendDemoEmailMutation } from 'features/user/services';

import { SendDemoEmailForm } from '../../../../features/communities/components/SendDemoEmailForm/SendDemoEmailForm';
import { GenericFlexModal } from '../../../ui/GenericFlexModal';

type DemoEmailProps = {
    readonly activator: React.ReactElement;
};

export const DemoEmail: React.FC<DemoEmailProps> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { notification } = App.useApp();

    const [createSendDemoEmail, { isLoading, isSuccess }] =
        useCreateSendDemoEmailMutation();

    const onCancel = () => {
        setIsOpen(false);
    };

    const onSubmit = values => {
        createSendDemoEmail({
            phone: values.phone,
            name: values.name,
            text: values.text,
            email: 'no-reply@spodial.com',
        });
    };

    useEffect(() => {
        if (isSuccess) {
            onCancel();
            notification.success({
                message: 'Успешно',
                description: 'Ваша заявка отправлена.',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    return (
        <Fragment>
            {props.activator ? (
                React.cloneElement(props.activator, {
                    onClick: () => setIsOpen(true),
                })
            ) : (
                <ActivatorButton
                    size='small'
                    shape='circle'
                    onClick={() => setIsOpen(true)}
                >
                    <QuestionOutlined />
                </ActivatorButton>
            )}
            <GenericFlexModal
                destroyOnClose
                isOpen={isOpen}
                onCancel={onCancel}
                onConfirm={onSubmit}
                title={'Оставить заявку'}
                okText={'Отправить'}
                footer={null}
            >
                <SendDemoEmailForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    isLoading={isLoading}
                />
            </GenericFlexModal>
        </Fragment>
    );
};

const ActivatorButton = styled(Button)`
    color: white;
    background-color: #6257d2;
    opacity: 0.4;
`;
